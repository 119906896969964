import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiLinkService } from '../api-link/api-link.service';

@Injectable({
  providedIn: 'root'
})
export class MediastreamService {
platWidth: any = 0;

loadIdx: number = 0;
trackCt: number = 18; // actual #of tracks /////////////////////<<<<<<< important

lkey: any;
rkey: any;  //random
banner: string;
currentTrack: string;
lastdescription: string;
lastmedia: string;
art1: any;
art2: any;
art3: any;

stream: any;
promise: any;
CurIsPlaying: boolean = false;
shownGroup:any;
stopdetect: any = "STOPPED";
tracks: any;
mStatus: any;


pcalc:number = 0;
ptype:any;

random:number = 0;  // ON or OFF
randstat: any;

randskip:number = null;
pReady: number = 0;
  

progresspxls: any = "0px";
pct: number = 0;
//isPlaying: any = this.CurIsPlaying; 


intm: number = 1000;
mediaType: any;
timestp: any = 0;
timestp2: number = 0;
halt:  number = 0;
cft: number = 0;
hlt: any;

playCt: number = 0;
dst: any;


//media
rOrd: any;
Idxs: any =[];
lastrx: any;
tracklist: any;
tListSrv: any = [

  {
  'thumb' : '../../assets/imgs/like.png',
  'mediatype' : 'Web Audio ',
  'art1' : '/assets/imgs/art/mgs.jpg',
  'art2' : '/assets/imgs/art/gps.jpg',
  'art3' : '/assets/imgs/art/99prob.jpg',
  'track' : this.apiLink.URL + '/_stream/Lake_Placid.mp4',
  'description' : 'Lake Placid - Gary S.',
  'rating' : '5',
  'banner' : '1',
  'banner1' : '/assets/imgs/banners/landgrab.png',
  'banner2' : '/assets/imgs/banners/mazi.jpg',
  'banner3' : '/assets/imgs/banners/know2.jpg',
  'vkey' :1,
  'progress': 0
  }
  ];     
constructor(public router: Router, 
public http: HttpClient,
public apiLink: ApiLinkService) {
 
this.tracklist = this.tListSrv;

this.azTracks(this.tracklist); // resort

  this.lkey = this.tracks[this.loadIdx].vkey;    // STORE VIA ACCOUNTS [INTRODUCED AS A NUMBER]
  this.banner = this.tracks[this.loadIdx].banner;
  this.currentTrack = this.tracks[this.loadIdx].track; //FIRST TRACK
  this.lastdescription = this.tracks[this.loadIdx].description;    // player bars
  this.lastmedia = this.tracks[this.loadIdx].mediatype;  // player bars
  this.art1;
  this.art2;
  this.art3;

  this.rkey = this.lkey;  //RANDOM after last played

  this.stream = new Audio(this.currentTrack);
  console.log('MediaStreamer READY - ',this.stream);

}


azTracks(data: any[]){
this.tracks = data.sort(function(a: { vkey: number; }, b: { vkey: number; },){
  
  if(a.vkey < b.vkey) return -1;
  if(a.vkey > b.vkey) return 1; 
  return 0;
  });
//console.log(this.tracks[0].vkey,this.tracks); 
}




public async makeRandom() {
  return await new Promise(resolve => {
  this.Idxs = [];  
  let array = this.tracklist;
  let fRec: number;
  
      //random
      let m = array.length, t, i; 
  
      // While there remain elements to shuffle…
      while (m) {
  
        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);
  
        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
  
        this.lastrx = array[i];
      }
  
          array.filter((item: {vkey: number; },index: number) => {
          if(index === 0) fRec = item.vkey;  
  
            let newKeys ={
              vkey: item.vkey
            };
  
          this.Idxs.push(newKeys); 
          });
  
   resolve(fRec);
    });
    }
  








stopThenStartQueue (trig: any) {  // USED BY QUEUE AND NEXT / BACK FUNCTIONS IN MEDIA SERVER TS
console.log(this.lastmedia); //type
console.log('triggered by: ' + trig + " >>" + this.lkey + " / " + this.currentTrack);

//pause & clear current stream STOP/ RELEASE
if(this.lastmedia !== "Video") this.stoptrack(); 
this.startStopPlaying(); 
}


// SHARED PLAY FUNCTION ALL BOTTOM BUTTONS
startStopPlaying () {  // Each bar invokes its own listener!!!
console.log('here to play')  
if(this.CurIsPlaying === true) {  
this.pausetrack();
} 
else{
  if(this.lastmedia !== "Video") {
  this.playtrack();
  this.QueListen(); 
  }

}
this.ScrollToVK();  
}         


pausetrack(){ 
this.CurIsPlaying = false;
this.stream.pause();
this.mStatus = 'paused';
console.log('============= PAUSED ' + this.progresspxls);
}


stoptrack() { 
this.stream.pause();
this.stream = ""; 
this.mStatus = '';
this.stopdetect = "STOPPED";
this.progresspxls = "0px";
this.pct = 0;
this.timestp = 0;
this.CurIsPlaying = false; 
console.log('============= STOPPED ' + this.progresspxls);
} 


async playtrack() {
if(this.lastmedia === "Video") {
console.log("done interval"); 
return;   
}


if(this.stopdetect !== "STOPPED") {
console.log('Already Queued  *******- CurrentTIME' + this.stream.currentTime); 
// already running audio service
}
else
{ //fixup new track

this.stopdetect = "";
this.progresspxls = "0px";
this.pct = 0;
this.timestp = 0;
this.timestp2 = 0;
this.halt = 0;
this.pct = 0;



this.stream = new Audio(this.currentTrack);   // NEW STREAM ////>>>        
console.log('Playing New Track  >>>' + this.stream.currentTime + " /progress " + this.progresspxls, this.currentTrack);
//starts audio stream//this.stream.loop = false;

//TIME TO LOAD PIXELS
await new Promise(resolve => setTimeout(resolve, 200 ));
this.dst = (this.platWidth  * .85).toFixed(2); //screen 375  80%
console.log(this.dst + "<platform === CT: " + this.stream.duration.toFixed(0));
};



//track already loaded
this.halt = 0;
this.cft = 0;
this.timestp = this.stream.currentTime -.25;
this.timestp2 = this.timestp;  
this.stream.play();
this.CurIsPlaying = true;
this.mStatus = 'playing';






let id = setInterval(async () => { 
this.timestp2 = this.timestp2 + this.intm/1000; 

let sc = this.stream.currentTime;
let sd = this.stream.duration

  if(this.mStatus === "paused"){
  this.timestp = sc;
  clearInterval(id);   
  return;
  } 
  


if(this.mStatus === 'waiting'){ 
  if(this.mStatus !== 'paused'){
    this.mStatus = 'playing';
    this.CurIsPlaying = true;
    
    }                       
}








if(this.CurIsPlaying === false || this.hlt === sc){
this.CurIsPlaying = false;  
this.mStatus = 'waiting';
this.timestp = sc;
this.halt = sc;
return;  
} 

 

let p:any = Number ((((sd - (sd - sc)) / sd)) * 100).toFixed(2)
if(p < 101){
this.pct = p;
} else this.pct = 100;


this.timestp = this.timestp + this.intm/1000;   
this.progresspxls = (this.dst * (this.pct /100)).toFixed(2) + 'px'; 
//console.log(this.progresspxls) ;
  





this.cft = 0;
this.halt = 0;
this.CurIsPlaying = true;
this.mStatus = 'playing';



let a = this.timestp.toFixed(2) - sc.toFixed(2);
  if(a > 4 ){ //5 waits for streamx
    this.cft = a;
        this.hlt = sc;
        this.timestp = sc;

        
  } 

}, this.intm);




this.promise = new Promise((resolve, reject) =>  {
this.stream.addEventListener('playing', () => {
resolve(this.stream.duration);
});

this.stream.addEventListener('paused', () => { 
resolve(true);
});

this.stream.addEventListener('error', () => {
reject(false);
});
});

 

return this.promise;
// promise only works outside of container HERE
}



fwdTime(){  
let sct =  this.stream.currentTime; 
let sd = this.stream.duration

if(sct === 0) return; 
let sc =  sct + 10;
this.stream.currentTime = sc; 
this.timestp = sc; 

let p:any = Number ((((sd - (sd - sc)) / sd)) * 100).toFixed(0)
if(p < 101){
this.pct = p;
} else this.pct = 100;

if(this.CurIsPlaying === false) this.progresspxls = (this.dst * (this.pct /100)).toFixed(2)  + 'px'; 
console.log('<<fwdTime>> ', sc);  
}



backTime(){  
let sct =  this.stream.currentTime; 
let sd = this.stream.duration
if(sct === 0) return; 
let sc =  sct - 10;
this.stream.currentTime = sc; 
this.timestp = sc; 

let p:any = Number ((((sd - (sd - sc)) / sd)) * 100).toFixed(0)
if(p < 101){
this.pct = p;
} else this.pct = 100;

if(this.CurIsPlaying === false) this.progresspxls = (this.dst * (this.pct /100)).toFixed(2)  + 'px';  
console.log('<<backTime>> ', sc);  
}




ended() {
this.promise = new Promise((resolve,reject) => {
this.stream.addEventListener('ended', () => {
console.log("ended END FUNCTION ")
resolve(true);
});

this.stream.addEventListener('error', () => {
reject(false);
}); 
});

return this.promise;
}   






getByKey(vkey: any){  // WORKING , INDEX, no declare input
  let newds: any;
  let newtr: any;
  let newtl: any;
  let banner:any;
  let idx:number;
  let art1:any;
  let art2:any;
  let art3:any;
  
  
  this.tracklist.filter((item: { vkey: any; description: any; track: any; mediatype: any; banner: any; art1: any; art2: any; art3: any; },index: number) => {
  if (item.vkey === vkey){
  newds = item.description;
  newtr = item.track;
  newtl = item.mediatype;
  banner = item.banner;
  idx = index;
  art1 = item.art1;
  art2 = item.art2;
  art3 = item.art3;    
  
  
  
  }
  }
  );
  //console.log('server GET BY KEY >> ',idx, vkey, newds, newtr, newtl, banner);			
  return([idx, vkey, newds, newtr, newtl, banner, art1, art2, art3]);  // 0, 1, 2, 3, 4, 5, 6, 7, 8
  }
  
  
  
  getByIndex(idx: any){  
  let vkey: any;    
  let newds: any;
  let newtr: any;
  let newtl: any;
  let banner:any;
  let art1:any;
  let art2:any;
  let art3:any;
  

  this.tracklist.filter((item: { description: any; track: any; mediatype: any; vkey: any; banner: any; art1: any; art2: any; art3: any; },index: any) => {
  if (idx === index){
  newds = item.description;
  newtr = item.track;
  newtl = item.mediatype;
  vkey = item.vkey;
  banner = item.banner;
  art1 = item.art1;
  art2 = item.art2;
  art3 = item.art3;  
  }
  });
  
  //console.log('server GET BY INDEX >> ',idx, vkey, newds, newtr, newtl, banner);			
  return([idx, vkey, newds, newtr, newtl, banner, art1, art2, art3])  // 0, 1, 2, 3, 4, 5
  }






NextTrack(){ //---------------- FOR AUTO PLAY QUE LISTENER

console.log(' SRV MSTATUS ===========  ',this.mStatus);
let goNext: number; 
let rIdx: number;   
let rlKey: number;

 
if(this.random === 1){

  this.Idxs.filter((item: {vkey: number;},index: number) => {
    if(item.vkey === this.rkey){
    rIdx = index;  //V12
    }
  });

  if(rIdx + 1 === this.tracks.length){  // CHECK TO STOP.
  this.makeRandom();  
  rIdx = 0; 
  //idx 0 start over or remix
  }else rIdx = rIdx + 1; //12 or 0


  this.Idxs.filter((item: {vkey: number;},index: number) => {
  if(rIdx === index){
  rlKey = item.vkey;  //V3
//next rdx
  goNext = this.getByKey(rlKey)[0];  //V13 11 --> V3 12
  this.InfoPlay(goNext,'random');
  this.rkey = goNext;
  return 'random';
  } 
  });

}
else goNext = this.getByKey(this.lkey)[0]+1;  // adds 1 to index LOOP     
console.log('CURRENT  ',goNext);

if(goNext < this.tracks.length){ 
this.InfoPlay(goNext,'next'); 
return 'next';
}
else{
// IF LAST TRACK 
  if(this.ptype ==='all'){ 
  goNext = 0;
  this.InfoPlay(goNext,'all');  
  return 'all';
  }
  else{

    // INFERS user pushed button at end
    if(this.mStatus === 'End of list'){
    console.log('FWD');  
    goNext = 0;            
    this.InfoPlay(goNext,'next');  
    return 'next';
    }


    if(this.mStatus !== 'End of list'){
    //this.mStatus = 'End of list';
    console.log('PLAYING LAST TRACK then EOL');  
    goNext = goNext -1;
    this.InfoPlay(goNext,'done');  
    return 'done';
    }     

 }                  
} 



}




FwdTrack(){
let goFwd:any;
goFwd= this.getByKey(this.lkey)[0] +1;   // adds 1 to index

if(goFwd > this.tracks.length-1){
goFwd = 0;
}
this.InfoPlay(goFwd,'fwd');
return 'fwd';
}




BackTrack(){
let goBack:any;
goBack= this.getByKey(this.lkey)[0] -1;   // substracts 1 from index

if(goBack < 0){
goBack = this.tracks.length-1;
}
this.InfoPlay(goBack,'back');
return 'back';
}







InfoPlay(idx: any,action: string){
if(action === 'done'){ 
this.mStatus = 'End of list';
return 'done'         
}

console.log(this.mStatus,' INFO STAT ');


let data: any = this.getByIndex(idx);

let nextKey:any = data[1];
console.log('AUTOFILL ',this.lkey,'   PLAY NEXT>>>    ',nextKey);

//SETUP FOR PLAY / RELINK
this.lkey = nextKey;
this.lastdescription = data[2]; 
this.currentTrack = data[3];
this.lastmedia = data[4]; 
this.banner = data[5];
this.art1 = data[6];
this.art2 = data[7];
this.art3 = data[8];

this.stopThenStartQueue('InfoPlay'); // IN LOOP      
}






selectiTrack(vkey: any, banner: string) {  // manually select track already in view INJECT TRACK TO STREAM
  
  let doplay: any;
  this.banner = banner;
  
  if(vkey !== this.lkey){ //changing track
  doplay = true;



  let data = this.getByKey(vkey);
  //SETUP FOR PLAY / RELINK
  this.lkey= vkey;     
  this.lastdescription = data[2]; 
  this.currentTrack = data[3];
  this.lastmedia = data[4];
  this.banner = data[5];  
  this.art1 = data[6];
  this.art2 = data[7];
  this.art3 = data[8];
  }
  else
    {//SAME
    if(this.CurIsPlaying){
    doplay = false;
    // allowed to toggle. Playeer already has banner from Current
    this.queToggle();
    }		
    else{
      //if was paused
      this.startStopPlaying ();
    }

  }	
  
    if(doplay){
    this.stopThenStartQueue('SelectiTrack');
    this.ScrollToVK();
    }
 
  }	
  

      
QueListen(){
 this.ended().then((f: boolean) => {   ///// PLAY ROUTE FOR ALL
    if( f == true){
      console.log(f , 'LISTENER AUTO NEXT'); 
      this.playCt = this.playCt + 1;
      if(this.playCt === this.tracks.length){
      this.makeRandom();
       console.log("end of list REMIX SHUFFLE"); 
       this.playCt = 0;
      } 

      if(this.ptype ==='one'){
        if(this.random === 0){


        this.stopThenStartQueue('Auto played'); // IN LOOP
        this.QueListen(); 
        }
        else{ this.ptype = '';
          this.pcalc = 0}
      }
      else{
            //////////////////////
            if(this.mStatus !== 'End of list'){
              this.NextTrack(); 
              this.QueListen();				
            }
            else{
              this.pausetrack();                             
              console.log("AUTO STOPPED - END OF LIST");
              }
          ///////////////////////		
        }
    }
      
      else{
        console.log('no response'); 
        }
   });

}


queToggle(){
  let vkey: any = this.lkey;
  if(this.lkey !== this.shownGroup){ 
  this.toggleGroup(vkey,this.banner);

  }
}



toggleGroup(group: any,banner: string) {  //V1 etc.
  if (this.isGroupShown(group)) {
    this.shownGroup = null;       // CAN CLOSE
  } 
  else if(banner !== '0'){
    this.shownGroup = group;
  }
}



isGroupShown(group:any) {
return this.shownGroup === group;
}




ScrollToVK(){
this.queToggle(); 

var page = document.getElementById(this.lkey);
//console.log(page);
  if (page !== null){
  page.scrollIntoView();		
  }


} 



Repeat(){
let repwhat: number  = (this.pcalc +1); 

  if(repwhat === 1){
  this.ptype = 'one';
  this.pcalc = 1;

    if(this.random === 1){
    this.random = 0;
    this.randstat = '';
    }

  }


  if(repwhat === 2){
  this.ptype = 'all';
  this.pcalc = 2;
  this.mStatus = '';
  }


  if(repwhat === 3){
  this.ptype = "("+this.tracks.length+")";
  this.pcalc = 0;
  }

}
  
  


async Random(){
let fRec: any; 

  if(this.ptype === 'one'){
  this.ptype = ''
  this.pcalc = 0;
  }

  let israndom = this.random + 1; 
  
  if(israndom === 2){
  this.random = 0;
  this.randstat = '';
  console.log('random OFF');
  }
  else
  { 
  this.tracks = []; //clear list 

  await this.makeRandom().then(async v => {
  console.log(this.lkey,fRec = v);

  if(v === this.lkey){
      //REMIX
      console.log("REMIX per last track = Idxs index");
      await this.makeRandom().then(n => {
        console.log(this.lkey,fRec = n);
      });
    
    }

    this.azTracks(this.tracklist);
    this.random = israndom;
    this.randstat = 'on';
    console.log('random ON',fRec);

  });


  
  


  }


  
}
    



}
