import { Component, OnInit, ViewChild } from '@angular/core';
import { Router} from '@angular/router';
import { ApiLinkService } from '../_service/api-link/api-link.service';
import { AppserviceService } from '../_service/appservice/appservice.service';
import { CanActivateGuard } from '../_guard/canAc-app-guard.service';
import { AppComponent } from '../app.component';
import { IonInput, NavController } from '@ionic/angular';



@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})


export class LoginPage implements OnInit {
@ViewChild('pwdInput', { static: false }) pwdInput : IonInput;

loginData = { username:'', password:'', secpin:'' };
msg: string;
goUrl: string;
permit: string;
errMsg: number;
dataVal: any;
dataSet: any = null;
isNewReg: boolean = false;

chxpin: any = null;
chxptime: any;
countdown:number = 0;
updPwdpass: boolean = false;

firstRun: boolean = true;



constructor(
public router: Router, public appC: AppComponent,
public appService: AppserviceService, public navCrtl: NavController,
public apiLink: ApiLinkService,    
public canGuard: CanActivateGuard) {
}


ngOnInit() {
}






async ionViewWillEnter(){
this.canGuard.permit = 0;  

}











scrollKeyLine(){
this.appService.scrollToElem('btmLine',true,true)
}



async ionViewDidEnter(){ //IF UI NEEDS TO VIEW COMPLETELY LOADED
this.appService.msg = null; 
this.appService.errMsg = null; 
this.appService.pinEye = "password";
this.appService.pEye = "password";




  if(this.appService.newpwd){
  this.isNewReg = true;
  this.loginData.username = this.appService.newEnrollee[1];
  this.loginData.password = this.appService.newpwd;
  this.appService.appFlushUser();
  this.goLogin(); 
  } 
  
}






backToWelcome(){
if(!this.appService.screenFull){
  this.appService.urlstate = '/?' + this.appService.rootId + '=';
  history.pushState (null, null,this.appService.urlstate); 
  }

this.canGuard.permit = 1; 
this.router.navigate(['/welcome'],{skipLocationChange: true}); 
}








async goEnroll(){ 
let proceed = await new Promise(async (resolve) => { 

  if(this.appService.refCode === '0000' || !this.appService.refCode){ //// update to Corp ID
    
  this.appService.refCode = this.appService.appInfo.corpId; 
    
    this.appService.connTimer('Updating referral ID',null,true);
    this.appService.userVerifyRefID().then(async e=>{
    this.appService.dataVal = "Stop Connection Timer"

        if(e){  
        } resolve(true); 
    }); 

    } else resolve(true);
    
  });



  if(proceed) {
  if(!this.appService.screenFull){
    this.appService.urlstate = this.appService.urlstate + '&profile=1';
    history.pushState (null, null,this.appService.urlstate); 
    }  
  this.canGuard.permit = 1;  // canAct to Login
  this.router.navigate(['/enroll'],{skipLocationChange: true });   
  } 

} 
















//Show/Hide HomeScreen Shortcut
tmpHS(){
  this.appService.tmpHS = 1;  
}

canceltmpHS(){
  this.appService.tmpHS = 0;  
}


/*
async KBgoLogin(){
await new Promise(resolve => setTimeout(resolve, 300 ));  
this.pwdInput.getInputElement().then(
inputElement => inputElement.blur());
this.goLogin();
}
*/



pinMatch(){
this.appService.pinEye = "password";  
this.appService.pEye = "password";
this.pwdInput.setFocus();
}



toggleEye(){
//IOS CANNOT SHOW KEYBOARD BUT ANDROID CAN
if(this.appService.pEye === "text"){
this.appService.pEye = "password";
} else this.appService.pEye = "text"; 

}


togglepinEye(){
  if(this.appService.pinEye === "text"){
    this.appService.pinEye = "password";
    } else this.appService.pinEye = "text";
}




backSpc(){
this.appService.bsp = 1;   
}



async showLC(){

  
  if(this.appService.bsp === 1){
  await new Promise(resolve => setTimeout(resolve, 350));  //must wait
  this.appService.bsp = 0;
  } 
  else {
  //hidden
  this.appService.sLC = false;
  if(this.appService.platWidth > 700 )  await new Promise(resolve => setTimeout(resolve, 350 )); //must wait
  this.appService.sLC = true;  
  }

}




async goUserLogin(){
return await new Promise<any>((logCreds) => {
let gotVal: any;
 

//bypass pw
    if(this.loginData.password === 'aaa'){
      this.loginData.username = "cgnsadmin";
      this.loginData.password = "123456Gs$";
    //eRme = true;
  }
  
    if(this.loginData.password === 'sss'){
      this.loginData.username = "bizmerch";
      this.loginData.password = "garyS123!";
    //eRme = true;
  }

    if(this.loginData.password === 'ccc'){
      this.loginData.username = "dbcust";
      this.loginData.password = "123456Gs$";
    //eRme = true;
  }

    if(this.loginData.password === 'ddd'){
      this.loginData.username = "dbuser";
      this.loginData.password = "123456Gs$";
    //eRme = true;
  }


  
    if(this.loginData.password === 'ooo'){
      this.loginData.username = "otadmin";
      this.loginData.password = "garyS123!";
    //eRme = true;
  }

    if(this.loginData.password === 'mmm'){
      this.loginData.username = "moonadmin";
      this.loginData.password = "garyS123!";
    //eRme = true;
  }

  

  if(!this.loginData.username || !this.loginData.password){
  this.appService.msg = "Username or password missing";
  return null;
  }


  
  if(this.loginData.username.length < 6 || this.loginData.password.length < 3){  // 6 for both
  this.appService.msg = "Incorrect credentials input";  
  return null;
  }




      let m: string;
      if(this.isNewReg === true){
      m = 'Account created \n logging in.'; 
      } //else m = 'Login';


 
      this.appService.connTimer(null,null,true);

      let lcreds: any = this.apiLink.getUserCreds(this.appService.rootId,
      this.loginData.username.replace(/^\s+|\s+$/g, ''),
      this.loginData.password.replace(/^\s+|\s+$/g, '')).subscribe(async creds => { 
      //this.appService.dataVal = "Stop Connection Timer"
      
      console.log(gotVal = creds[0]);  
     

      if(gotVal.toString().includes('denied')){      // first record
      this.appService.msg = gotVal;      
      }else
        { 

           
          if(this.appService.rootId !== gotVal.profileGroup){
            alert("Not found in profile group")
            return;
          }
          

          this.appService.credsInfo = gotVal;  //Logging in


          //NO CodeId field
          this.appService.refCode = gotVal.userId;
          this.appService.refUser = gotVal.firstName + ' ' + gotVal.lastName;
          this.appService.refProfileType = gotVal.profileType;
          this.appService.refProfileGroup = gotVal.profileGroup;
          
          
          let uCreds = {pGroup: gotVal.profileGroup, pType: gotVal.profileType}
          logCreds(uCreds);
      }   
                
    lcreds.unsubscribe();
    }, err=>{
      console.log(err);
    });  
          
  });
}





async goLogin(){
this.appService.pEye = "password"; 
this.appService.dataVal = null;




await this.goUserLogin().then(async e=> {
  this.canGuard.permit = 1;
 // console.log(
    this.appService.isLoggedIn = e.pType
    //);


   if(e){


    if(!this.appService.screenFull){
    this.appService.urlstate = '/?' + this.appService.rootId + '=';
    history.pushState (null, null,this.appService.urlstate); 
    }
    

    if(this.appService.appInfo.ext){
    this.navCrtl.navigateRoot('/launch-ext',{ skipLocationChange: true  });
      } 
      else {

        this.appService.toolBarClass = "homeBG"
        this.appService.sideMenu(); 
        this.navCrtl.navigateRoot('/app/home',{ skipLocationChange: true  }); // pop & clear
        
        if(this.appService.isLoggedIn === 'Carrier'){   
           await this.appService.getLanes(); 

          //console.log("lanes: ",this.appService.userDefs.length );
          if(this.appService.userDefs[0]){
            this.appService.includeDefList = true;
           await this.appService.updateItems('FIRST RUN',null,'progb',null);
          } else this.appService.dataVal = "Stop Connection Timer";
          
        } 
        else {   
        
            let c: any = 
             {orgn: "", 
              dest: "", 
              equip: "V", 
              frs: "2023-02-01T05:00:00.000Z", 
              tos: new Date().toISOString(), 
              exStates: "",
              minMiles: "",
              minRpm:""
              }
    
      await  this.appService.updateItems('b',c,'progb',null);
        } 

      } 
   } 


 
  });  


}





async chxPwd(){
this.appService.pinEye = "password";
this.appService.pEye = "password";

this.chxpin = null;
this.appService.msg = null; 



    this.appService.connTimer('Verifying username',null,true);      
    let lcreds: any = this.apiLink.chkUsername(this.appService.rootId,
    this.loginData.username.replace(/^\s+|\s+$/g, '')).subscribe(async creds => {
    this.appService.dataVal = 'Stop ConnTimer'; 
   
    let gotVal: any = creds && creds[0];
  
    if(gotVal.toString().includes("not found")){      // first record
    this.appService.msg = gotVal;      
    }else
      {
   
        this.appService.msg = 'Check your email for secret pin';
        this.chxpin = gotVal;
        this.chxptime = new Date();  
       
        this.countdown = 60;
        this.loginData.password = '';  
        this.loginData.secpin = ''; 
        this.appService.doChgPwd();          
        this.ctDown();
    }   
              
  lcreds.unsubscribe();
  }, err=>{
    console.log(err);
  });
  
  
  }
  

async ctDown(){

let ct = this.countdown;

for(let i=0; i < ct && this.countdown > 0; i++){ 

await new Promise(resolve => setTimeout(resolve, 1500 )); 
this.countdown = this.countdown - 1;
}

if(this.countdown === 0) this.appService.msg = 'Secret pin expired';
this.appService.msg = null;
this.appService.chgPwd = false;
}  



ctCancel(){
if(this.countdown > 0){
  this.appService.msg = null;
} 

this.appService.chgPwd = false;
this.countdown = 0;

}



  async goLoginPCX(){
// update sefver - then login

if(!this.loginData.secpin){
  this.appService.msg = 'Please input secret pin';
  return;
}



if(this.countdown < 1){
  this.appService.chgPwd = false;
  this.appService.msg = 'Secret pin expired';
  return;
}


if(this.loginData.secpin !== this.chxpin){
  this.appService.msg = 'Incorrect secret pin';
  return;
}




let p = this.loginData.password;
let pattern = new RegExp("^(?=.*[a-z|A-Z])(?=.*\\d)(?=.*[.!@$^&_+-]).+$"); //include

if(pattern.test(p) && p.length > 5 && p.length < 11){
  this.updPwdpass = true;
} 
else {
  this.updPwdpass = false;  
this.appService.msg = "Incorrect password input";
return; 
}


this.appService.connTimer('',null,false);  
let lcreds: any = this.apiLink.chgPass(this.appService.rootId,
  this.loginData.username.replace(/^\s+|\s+$/g, ''),
  this.loginData.password.replace(/^\s+|\s+$/g, '')).subscribe(async creds => {
  this.appService.dataVal = 'Stop ConnTimer'; 
 
 let gotVal: any = creds && creds[0];

  if(gotVal ==="Update credentials fail"){      // first record
  this.appService.msg = gotVal;      
  }
  else{
    this.appService.msg = null;
    this.countdown = 0;
    this.goLogin();   
  } 
            
lcreds.unsubscribe();
}, err=>{
  console.log(err);
});  
     
}



async getCpgns(pGroup: any, pType: any){
this.appService.uCpgns = null;

      let data = this.apiLink.apiCpgns(pGroup,pType).subscribe(cpgns => {
      //ignore per login clears Timer
      this.appService.uCpgns = cpgns[0];
      this.appService.defcpgn = this.appService.uCpgns.cpgnId;

        data.unsubscribe();
      }, err => {
        console.log(err);
      });
}









}
