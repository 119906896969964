import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppserviceService } from '../_service/appservice/appservice.service';

@Component({
selector: 'app-item_chalkboard',
templateUrl: './item_chalkboard.page.html',
styleUrls: ['./item_chalkboard.page.scss'],
})
export class Items_chalkboardPage implements OnInit {


chkBtn: string = "Delete All";
valcpgn: boolean;
moreLess: boolean = false;
selectedSegment: string = "contract";
viewOpts: boolean;



constructor( public appService: AppserviceService,
public router: Router
) { 

}




ngOnInit() {
}




ionViewWillEnter(){
  if(this.appService.cbalert > 0) this.appService.sortOpts('c','Age - Newest');
  } 




async ionViewWillLeave(){  
this.appService.showSort = false;
}








async removeFav(inv,shp, typ){

if(typ === 'cnld' && this.appService.inCrrReqs.includes(inv)){
await this.appService.reqFreight(inv,shp, true); //skip
}  
//user remove



let p: any = await this.appService.appConfirm("Delete Confirm","Are you sure?",null,"Yes","No");
//console.log(p)

if(p === 3) return;   


  if(this.appService.isCbOpen === inv){
    this.appService.isCbItem = "";
    this.appService.isCbOpen = "";
    this.selectedSegment = "contract";    
   }  

this.appService.removeCbList(inv,false);
}


meResize(){
if(!this.selectedSegment) this.selectedSegment = "contract"; 
}




async segmentChanged(v: any) {
if(this.viewOpts){
//  console.log(v, this.appService.isCbOpen+ '-Cb')
this.viewOpts = false;

await new Promise(resolve => setTimeout(resolve, 200));
this.appService.scrollToElem(this.appService.isCbOpen+ '-Cb',true,true)

} 
else {
this.viewOpts = true;
await new Promise(resolve => setTimeout(resolve, 200));
this.appService.scrollToElem(v,false,true)
} 
}



togglemoreLess(){
if(!this.moreLess){
this.moreLess = true;  
} else {
this.moreLess = false;
} 
}


 
async toggleIsOpen(x: string, fs: string){   
this.appService.showSort = false;

this.appService.clearAlertMsg();
this.appService.clearAlerts(x);



if(this.appService.isLoggedIn === 'Carrier' && fs !== 'Unassigned') return;



if(this.appService.isCbOpen !== "") {
this.appService.isCbOpen = "";  
this.moreLess = false; 
if(this.appService.isCbItem === x) return; ; 
}  





await new Promise(async resolve => {
if(this.appService.isCbItem !== x){  //SWITCHING
this.appService.isCbItem = x;  
  if(this.appService.isCollapsed){
  this.selectedSegment = "contract";   
  this.viewOpts = false;
  resolve(true);
  } 
} 



  this.appService.isCbOpen = x;
  await new Promise(resolve => setTimeout(resolve, 200));
  await this.appService.scrollToElem(x + '-Cb',true,true).then(async ()=>{
  await this.appService.hgtResize('detsCb','Toggle');
 // this.moreLess = true; 
  });

}); 

}
  




  
async offBoard(){
let p: any = await this.appService.appConfirm("Clear Chalkboard","Are you sure",null,"Yes","No");

if(p === 3) return; 



  this.appService.hiddenButton = true;

  if(this.appService.apiItemsList) this.appService.goTab('items_page',null);  
   else this.appService.goTab('item_search',null); 

   this.appService.removeAllCbList();

  this.appService.isCbOpen = "";
  this.appService.isCbItem = "";   
  this.appService.hiddenButton = false;
  }  
  

}
