import { Component, HostListener, OnInit} from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { NgZone } from '@angular/core';
import { CanDeactivateGuard } from './_guard/canDe-app-guard.service';
import { AppserviceService } from './_service/appservice/appservice.service';
import { CanActivateGuard } from './_guard/canAc-app-guard.service';
import { ApiLinkService } from './_service/api-link/api-link.service';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { MediastreamService } from './_service/mediastream/mediastream.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})


export class AppComponent implements OnInit {  
name = 'Angular 5';  
appver: string = '1.015';


/// Idle // =====> fwd with masking // ===== temporary 302

idleState = null; //'Not started.'
timedOut = false;
min: any = null;
sec: any = null;
curmin: any;

iSub: any;
oSub: any;
toSub: any;
towSub: any;

@HostListener('window:resize', ['$event'])
onResize() {
this.isResized();
}


currentRoute: string;





constructor(public idle: Idle,private zone: NgZone,
public appService: AppserviceService,  public menuCtrl: MenuController,
public canGuard: CanActivateGuard, public deactGuard: CanDeactivateGuard,
private platform: Platform, public apiLink: ApiLinkService,
public player: MediastreamService,
private router: Router) {


this.router.events.subscribe((event: Event) => {
  switch (true) {
     
    
    case event instanceof NavigationStart: {
   // this.appService.loading.present("loading..");

    

      break;
    }

    case event instanceof NavigationEnd:
    case event instanceof NavigationCancel:
    case event instanceof NavigationError: {
    //this.appService.loading.dismiss();
    
    this.appService.urNav();


    
      break;
    }
    default: {
      break;
    }


  }
});

this.initializeApp();


}


ngOnInit(){
}







isResized(){
  let w = this.platform.width();
  this.appService.platWidth = w;
  this.player.platWidth = w;
  
  let h = this.platform.height();
  this.appService.platHeight = h; 
  
  
  if(w < this.appService.colpsW){
    this.appService.isCollapsed = true; 
    this.appService.topLeftMenuHidden = true;  
    } 
    else if(w > this.appService.colpsW -1){
    this.appService.isCollapsed = false;   
    this.appService.topLeftMenuHidden = false;
    this.appService.topSectionScrolls = "";  
    this.appService.showSort = false;
  } 

  if(w < this.appService.mobiW){
    this.appService.isMobiW = true;  
    } 
    else if(w > this.appService.mobiW -1){
    this.appService.isMobiW = false;   
  } 

 // console.log(w + " x " + h)
}

async initializeApp() {
this.platform.ready().then(async () => {


let hn = window.location.hostname;
this.appService.hostname = hn;

if(hn.includes(".com")){
this.apiLink.API = this.apiLink.API2;  
} else 
this.apiLink.API = this.apiLink.API1; 

this.isResized();
//this.statusBar.hide();
//this.splashScreen.hide(); 
this.appService.introPos = this.appService.platHeight - 325;


this.pgRefresh(); 


this.appService.appver = this.appver;
this.player.makeRandom();  
//alert("Host: "+  window.location.hostname)

});
}










pgRefresh(){
window.addEventListener("keyup", disableF5);
window.addEventListener("keydown", disableF5);
function disableF5(e) {
if ((e.which || e.keyCode) == 116) e.preventDefault(); 
};

}




//MUST REMAIN IN APP COMP TO INIT GUARDS
runAppTimeOut(){
console.log('START TIMEOUT WATCH') 

  let appTimeOut: number = this.appService.appInfo.timeOut;
  console.log(appTimeOut); //seconds

  this.idle.setIdle(appTimeOut);  
  this.idle.setTimeout(appTimeOut/2);  //half the timeOut
  this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  
  this.iSub = this.idle.onIdleEnd.subscribe(() => {
  this.idleState = null;//. 'No longer idle'
    this.zone.run(() => {
    console.log('force update the screen');
    });
  });


    this.toSub = this.idle.onTimeout.subscribe(() => {

    this.idleState = 'Timed out!';
    this.timedOut = true;
    
    //UNSUBSCRIBE
    this.iSub.unsubscribe();  
    this.toSub.unsubscribe();
    this.oSub.unsubscribe();
    this.towSub.unsubscribe();
    this.idle.stop();
    console.log('TIMED OUT');
    
    this.deactGuard.isLogout = 1;
    this.canGuard.isLogout = 1;
    this.canGuard.permit = 1;
    window.location.reload();
  });



  this.oSub = this.idle.onIdleStart.subscribe(() => this.idleState = 'Session idle!');

  this.towSub = this.idle.onTimeoutWarning.subscribe((countdown) => {
      let data = countdown/60;
      this.min = data.toString().split('.')[0];
      if(this.min > 0){
        this.curmin = this.min +' min ';  
      } else{
        this.curmin = '';
      }
      this.sec = parseFloat(0+'.'+data.toString().split('.')[1])*60;
      this.sec = (Math.round(this.sec * 100) / 100);
      console.log(countdown)

    this.idleState = 'Inactivity timeout ' + this.curmin  +this.sec+'  seconds';
  });
      this.reloadTimer();
}




reloadTimer() {
//alert('reloaded ' + this.router.url)  
this.idle.watch();
this.idleState = null;//'Started.'
this.timedOut = false;
}
  


}
