import { Component, OnInit} from '@angular/core';
import { Router} from '@angular/router';
import { AppserviceService } from '../_service/appservice/appservice.service';
import { NavController } from '@ionic/angular';
import { CanActivateGuard } from '../_guard/canAc-app-guard.service';
import { CanDeactivateGuard } from '../_guard/canDe-app-guard.service';
import { AppComponent } from '../app.component';
import { MediastreamService } from '../_service/mediastream/mediastream.service';


@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})




export class TabsPage implements OnInit {
selectedSegment: string = "chalkboard";



constructor(public router: Router, public navCtrl: NavController,
public appService: AppserviceService, //keep   
public appC: AppComponent,          
public canGuard: CanActivateGuard, 
public deactGuard: CanDeactivateGuard) { 

this.canGuard.permit = 0; // set def for <> navigation 
//console.log('initialized tabs');
this.appService.loginFromUrl = this.appService.urlstate;

}





           
ngOnInit() {
  
}

async goTop(){
  
if(this.appService.fHalert > 0){
this.appService.goTab('frhistory',null); 
} 
else {
this.appService.topSectionScrolls = this.appService.topSectionScrolls.replace("items_page:",'');
  if(!this.router.url.includes('items_page')){    
  this.appService.goTab('items_page',null)
    await new Promise(resolve => setTimeout(resolve, 500));  
        
    if(this.appService.isMobiW) this.appService.scrollToElem('topRow',true,false);
    else if(this.appService.isCollapsed) this.appService.scrollToElem('topTab',true,false);

    await new Promise(resolve => setTimeout(resolve, 60)); 
    this.appService.scrollToElem('itemsContent',true,false);   
  
  } 
}

this.appService.showLA = false;
this.appService.itemAlertMsg = null;
}


ionViewWillEnter(){

} 


ionViewWillLeave(){
this.appC.menuCtrl.close();
}




goLink(urLink: string, urTitle: string){
if(urLink === '/welcome'){ 

  if(!this.appService.screenFull){
    this.appService.urlstate = "/?" + this.appService.rootId + "=&login=1"; 
    history.pushState (null, null,this.appService.urlstate); 
    }
  window.location.reload();
  return 
}

this.canGuard.permit = 1;  


this.appService.goTab(urLink,null);
}
 


async backPS(){
this.appService.goTab('items_page',null);
}




}
