import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot  } from '@angular/router';
import { AppserviceService } from '../_service/appservice/appservice.service';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
permit: number = 0; 
isLogout: number = 0;
isEnroll: number = 0;
cartLock: boolean = false;
goToUrl: string;

constructor(public router: Router, public appService: AppserviceService) {
  }




navStats(){
//console.log("ROUTE STATS" + this.router.url + ' <> '+ this.goToUrl + ' permit? ' + this.permit);
}

canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot,state: RouterStateSnapshot) {

this.goToUrl = '/'+ route.parent.routeConfig.path;  
this.navStats();


/*
//0 FROM TAB CLICK
if(this.router.url === '/launch-ext'){  
history.pushState (null, null,'/launch-ext'); // NEEDED FOR BACK ======> "" not "/"
history.pushState (null, null,'/launch-ext'); // NEEDED FOR BACK ======> "" not "/"
return false;  
} 
*/



//1 FROM TAB CLICK
if(this.router.url === '/app/enroll' && this.goToUrl === '/'){ 
return true;
} 



//2 FROM TAB CLICK
if(this.router.url === '/app/cart' 
&& this.goToUrl === '/'
&& this.cartLock === false) return true;




//3 
if(this.permit > 0){
this.permit = 0;
return true; 
}




//7 BOUNCE BACK 
let ur = this.router.url;
let urto

if(ur.includes(";")){
urto = ur.slice(0, ur.indexOf(";"));
} else urto = ur;


this.router.navigate([urto],{ skipLocationChange: true});  
history.pushState (null, null,this.appService.urlstate); // NEEDED FOR BACK ======> "" not "/"
history.pushState (null, null,this.appService.urlstate); // NEEDED FOR BACK ======> "" not "/"
console.log("reject deactivate");
return false; 

} // end observable


} // end class