import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class ApiLinkService {

public URL = 'https://appbrid.com' //not read
public API = '';


public API1 = 'http://198.38.94.14:8181'; // local nonSSL
public API2 = 'https://gsglobalsrv.com'; // per Tomcat host @Cmocha - hosted
public auTkn: string = 'E5F5079DA611B44C80C145C99E8F3227';
//to pull from server not TS file


 


constructor(
public http: HttpClient) {
}




geoZips(zip: string): Observable<any> {
let loc = "https://singlesearch.alk.com/NA/api/search?authToken=" 
+ this.auTkn 
+ "&query=zip:" + zip 
+ "&countries=US"
return this.http.get(loc);
}




optStops(data: string, opt: string):Observable<any> {
let stopsdata = JSON.stringify(this.apiCharTurnCate(data));
console.log(opt, stopsdata)
return this.http.get(this.API + '/api/optstops/'+  opt + '/' + stopsdata );
}


//get stops
apiGetStops(inv: string, stp: any):Observable<any> {
 // console.log("get stops: ", inv, stp) 
return this.http.get(this.API + '/api/stops/' + inv +'/' + stp);
}
  






optLane(data: any, opt: string):Observable<any> {
let lanesdata = JSON.stringify(this.apiCharTurnCate(data));  
console.log(opt,lanesdata)
return this.http.get(this.API + '/api/optlanes/'+  opt + '/' + lanesdata );
}
  


apiLanes(grp: string, uId: string):Observable<any> {
return this.http.get(this.API + '/api/lanes/' + grp  + '/' + uId);
}



apiItems(itemsdata: any):Observable<any> {
let data = JSON.stringify(itemsdata);  
//console.log(data)
return this.http.get(this.API + '/api/itemsrch/' + data);
}


//RETRIEVE IN GRPS TABLE
userLists(grp: string, userId: string):Observable<any> {
return this.http.get(this.API + '/api/useritems/' + grp  + '/' + userId);
}

//STORES IN GRPS TABLE
apiInvUser(invN: string, grp: string, userId: string, typ: string):Observable<any> { 
return this.http.get(this.API + '/api/invUser/'+ invN + '/' + grp  + '/' + userId+ '/' + typ);
}

remFavItem(invN: string, grp: string, userId: string):Observable<any> {
return this.http.get(this.API + '/api/remFavItem/'+ invN + '/' + grp  + '/' + userId);
} 
  
apiInvRff(a: any):Observable<any> {
return this.http.get(this.API + '/api/invRff/'+ a.invN + '/' + a.shipId  + '/' + a.userId  + '/' + a.iStatus);
}

remInvRff(a: any):Observable<any> {
return this.http.get(this.API + '/api/reminvRff/'+ a.invN + '/' + a.shipId  + '/' + a.userId  + '/' + a.iStatus);
}

getInvRff(a: any):Observable<any> {
return this.http.get(this.API + '/api/getRff/'+ a.invN + '/' + a.userId + '/' + a.typ);
}

apiPosts(postdata: any, typ: any):Observable<any> {
let data = JSON.stringify(this.apiCharTurnCate(postdata)); 
//==============console.log(typ,data); 
return this.http.get(this.API + '/api/itempost/'+ data + '/' + typ);
}


//LOAD ALERT - FOR EMAIL
apiItemAlert(itemAl: any):Observable<any> {
let data = JSON.stringify(itemAl); 
console.log(data); 
return this.http.get(this.API + '/api/itemAlert/'+ data);
}



apiInvSeat(dy: string,seat: string):Observable<any> {
return this.http.get(this.API + '/api/seat/'+ dy + '/' + seat);
}





getAppData(app: string): Observable<any> {
return this.http.get(this.API + '/api/appdata/' + app);
}


getUsersList(entId: string): Observable<any> {
return this.http.get(this.API + '/api/viewUsers/' + entId);
}


getUser(srchVal: string,srchEnt: string,matchVal: string): Observable<any> {
return this.http.get(this.API + '/api/' + srchVal + '/'  + srchEnt + '/' + matchVal);
}



regUser(userData: any): Observable<any> {
let data = JSON.stringify(userData);
return this.http.get(this.API + '/api/enroll/' + data);
}



chgPass(ent: string, eUid: string,ePwx: string): Observable<any> {
return this.http.get(this.API + '/api/chgP/' + ent + '/'  + eUid + '/' + ePwx);
}
  



getRefPhone(refLink: string):Observable<any> {
return this.http.get(this.API + '/api/reflink/' + refLink);
}

  
optIN(sentData: any, addKP: boolean): Observable<any> {
let data = JSON.stringify(sentData);  

if(addKP === true){   
return this.http.get(this.API + '/api/cpSent/' + data + '/' + addKP);  

}
else{
return this.http.get(this.API + '/api/updateKPN/' + data);
}

}



getUserCreds(ent: String, eUxn: string,ePwx: string): Observable<any> { 
  let user = {
    "profileGroup" : ent,
    "userName" : eUxn,
    "encrytedPassword" : ePwx
  }
  
  let sd = this.API+'/api/userCreds/'; 
  return this.http.post(sd, user);
  }


updateProfile(userData: any): Observable<any> {
let data = userData
let sd = this.API+'/api/updateProfile/'; 
return this.http.post(sd, data);
}


getAppUser(userId: string): Observable<any> { 
return this.http.get(this.API + '/api/getUser/' + userId );  
}
    




chkUsername(ent: String, eUxn: string): Observable<any> { 
return this.http.get(this.API + '/api/chkUser/' + ent + '/i/' + eUxn );  
}


getAppTkn(ent: String, uId: string): Observable<any> { 
return this.http.get(this.API + '/api/apptkn/' + ent + '/u/' + uId);  
}


checkAppTkn(tkn: string): Observable<any> { 
return this.http.get(this.API + '/api/checkapptkn/' + tkn);  
}


getInviteData(refCode: any, ent: any, cpgn: any,): Observable<any> { 
return this.http.get(this.API + '/api/invitedata/'+ refCode + '/e/' + ent + '/c/' + cpgn);  
}
  

apiCpgns(pGroup: any, pType: any):Observable<any> {
return this.http.get(this.API + '/api/cpgnGrp/' + pGroup + '/' + pType);
}


apiProducts(entId: string, uId: string):Observable<any> {
let ld = this.API + '/api/products/'+ entId + '/u/' + uId;
return this.http.get(ld);
}



apiCharTurnCate(obj){
  Object.keys(obj).forEach(col =>{

  let value = obj[col];
  
  if(!value ) return; // if undefined
  if(typeof(value) === "string" ){
  

      obj[col] = value
      .replace(/\r\n/gi,"@rn@")
      .replace(/\r/gi,"@rcr@")
      .replace(/\n/gi,"@ncr@")
      .replace(/\\/gi,"@bsl@")
      .replace(/\//gi,"@fsl@")
      .replace(/\#/gi,"@htg@")
      .replace(/\%/gi,"@pct@")
      .replace(/\?/gi,"@qsm@")
      .replace(/\[/gi,"@lbr@")
      .replace(/\]/gi,"@rbr@");  
    }
  
  });
  
  return obj;
  }


  
  //example
  save(user: any): Observable<any> {
    let result: Observable<Object>;
    if (user['href']) {    // HAS FIELD DATA FOR HREF
      result = this.http.put(user.href, user);
    } else {
      result = this.http.post(this.API, user)
    }
    return null;
    //result.catch(error => Observable.throw(error));
  }










}
