import { Injectable, ViewChild} from '@angular/core';
import { Router,RoutesRecognized} from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { AlertController, IonInput, NavController, ToastController} from '@ionic/angular';
import { ApiLinkService } from '../../_service/api-link/api-link.service';
import { isPlatform} from '@ionic/angular';

import { LoadingService } from '../loading/loading.service';
import { MediastreamService } from '../mediastream/mediastream.service';



@Injectable({
  providedIn: 'root'
})
export class AppserviceService {
@ViewChild('qualInput', {static: false}) qualInput: IonInput; 


defsPost: any  = {
  crr: "MAY DAY TRUCKING",
  crrAddress: "5600 S BAY",
  crrAddress2: "UNIT 55",
  crrCity: "Atlanta",
  crrContact: "Dave Allen",
  crrData: "PASS",
  crrEmail: "billyortese@astrdotcom",
  crrId: 0,
  crrNotes: "na",
  crrPhone: "555-666-444",
  crrState: "GA",
  crrZipcode: "30056",
  creditInfo: "PASS",
  freightDloc: "Atlanta, GA",
  freightStops: 0,
  freightdPo: "850000",
  dhd: "12",
  dho: "52",
  crrdotAuth: "5894000",
  editedBy: "GARY",
  freightEquipment: "V R F",
  freightExtras: "1. Palletized rail racks\r\n2. No touch / assisting\r\n3. Tracking is required",
  invDate: "2023-03-03",
  freightinvRate: "3,800",
  invRpm: "3.80",
  invoiceNotes: "NA",
  freightitemDetails: "The VERY UPMOST purpose of a product description is to supply customers with important information about the features and benefits of the product so they're compelled to buy.The VERY UPMOST purpose of a product description is to supply customers with important information about the features and benefits of the product so they're compelled to buy.",
  itemInvoice: "",
  freightitemWgt: "37500",
  crrmcAuth: "895550",
  freightmilesBill: "0",
  modified: "No",
  freightOloc: "Dallas, TX",
  freightoPo: "850000",
  otherCharges: "0.00",
  pmtMethod: "YES",
  posted: "",
  printed: "",
  freightserviceOrder: "DRY VAN",
  settledAmt: "3,750.00",
  settledDate: "2023-03-03",
  shipAddress: "8900 WEST LAKE",
  shipAddress2: "STE 5",
  shipCity: "Dallas",
  shipCo: "PEPSI",
  shipContact: "John Green",
  shipEmail: "green@pdotcom",
  shipId: 0,
  shipNotes: "NOT SET",
  shipPhone: "888-888-0000",
  shipState: "TX",
  shipZipcode: "33380",
  totals: "3,800",
  trueRate: "3,800",
  trueRpm: "3.80",
  spotRate: "5,800",
  spotRpm: "5.80",
  freightpickFrom: "",
  freightpickTo: "",
  freightpickTime: "10AM EST",
  freightdropFrom: "",
  freightdropTo: "",
  freightdropTime: "3PM EST",
  freightitemStatus: "Inactive",
  freightOGps: "",
  freightOZipcode: "",  
  freightDGps: "",
  freightDZipcode: "",
  lastAlert: "", 
  };
rootId: any = null;  // 1. from Url visit: ENTITY
codeId: any = null;  // 2. from initial Url ONLY set in splash: valid Url referral code (user or null)
refCode: any = null; // 3. current Enroller referrer / corpId id codeid is null
                            // USED BEFORE LOGIN to populate CredsUserInfo
todaySQL: string;
miniHgt: number = 320;

appver: string = ''; // set from appComp
urlcode: any = null;
urlstate: any = null;
loginFromUrl: any = null;
didEnd: any ='0';
entMsg: any = '';
extReg: any = '';
/*
1. BABY STEP
2. TEST
3. ASSUME ACCEPTANCE
4. CLOSE (OFFER 2 CHOICES)
*/


progress: any = 0;
//PLATFORM
platWidth: any = 0;
platHeight: any = 0;
isIOS: boolean;
screenFull: boolean = false;
//screen: any;

//nav initial
navigate: any =[];
pgTitle: any = '';
toolBarClass: string = 'appTbBG';


toolBarOwn: string = '';
noHdrLine: string = 'home items_page lanes policyterms ratecon item_invoice pklist'
noTabHdr: string = 'home enroll items_page lanes item_search posting policyterms ratecon item_invoice pklist';
noTabs: string = 'ratecon item_invoice posting pklist';
noProgbar: string = 'pklist';

topSectionScrolls: string = ""; // leave unset
viewTabs: boolean = true; // for cart

//delayHdr: boolean = true;
pageload: number = 350;

//HOME SCREEN
tmpHS: number = 0; 
chgPwd: boolean = false;


//Connection Timer
dataVal: any;


//APP DATA
prevUrl: any = null; 
timeOutVal: number = 600;
timeDelay: number = 1000;
alertRes: boolean = null;
appInfo: any = null;
apptoken: any = null;



//app launch
tokenUrl: any;
appverify: any;


/////////////
errMsg: any = null;
msg: any = null;
dblButton: boolean = false;
hiddenButton: boolean = false;
bkButtonHide: boolean = false;


//LOADS
isItem: string = "";
isCbItem: string = "";
isFhItem: string = "";


isOpen: string = "";
isCbOpen: string = ""; 
isFhOpen: string = "";

apiItemsList: any = []; 
maxCatIdx: number;
//searchN: any = [];
searchY: any  = [];
//itemSrch: any = []; 
didSrch: boolean = true; 

orgSrch: any = ''; 
dstSrch: any = '';
equSrch: any = '';  
frSrch: any = '';
toSrch: any = '';

//totalCT: number = 0;
prevTotalCT: number = 0;




mainPromol1: string = 'Thank you!';
mainPromol2: string = 'Get discounts of up to $200';


//OPEN FROM REFERRAL LINK
invNbr: any;
cpgnId: any;
srchCode: any;
refByCHX: boolean = false;



//REFERRAL DATA
udpRefC: boolean = false;
refLink: any = null;
sentPhone: any = null;
optPhone: any = null;
optConf: any = "";
cxPhNum: number = 1;


refUser: any = null;
refProfileGroup: any = null;
refProfileType: any = null;

//NEW USER
newEnrollee: any = null;
newpwd: string = null;

curCt: number = 0;
keyCt: number = 0;

//LOGGED IN
credsInfo: any = null;
uCpgns: any = null;
isLoggedIn: any = null;
  

//REPORTS
usersReportInfo: any;
usersReportInfoCT: any;
  
//CART & ORDER
cartCt: number = 0;
didOrder: number = 0;
inCart: any = [];
cartTotal: number = 0;


//CHALKBOARD
inCBoard: any = [];

inFHistory: any = [];


//CAMPAIGNS
defcpgn: any = 0;
uCpgnCt: any = 0;


///media







//screen
screenInfo: string;
topLeftMenuHidden: boolean = false;
colpsW: number = 1099;
isCollapsed: boolean;
mobiW: number = 640;


stopItem: boolean = false;
isItemUpd: string = "Done";  


pEye: string = "password";
pinEye: string = "password";
sLC: boolean = false;
bsp: number = 0;

detailH: any; //def
dvHgt: any = 65; //actual -1
oFlowDets: boolean;
detailW: number;



itemIntv: any = null;
syncIntv: any = null;
syncIncr: number = 750;
syncProgress: any;
itemTimer: number = 20000;
last_itemUpdate: string = new Date().toISOString();
last_stopsUpdate: string = new Date().toISOString();

itemAge: number = 30;

showLA: boolean = false;
itemAlertMsg: string;
tmpCt: number = 0;  

hostname: string;
isMobiW: boolean;
clientHgt: number;
lastScroll: string = "";
inputErr: string = "";
validPost: boolean = false;
lastPostErr: string = "";
fail_post: string = "";
curUrl: string = "";


sDate: string = "";
showCal: boolean = false;

today: string;
userDefs: any = [];



searchparams1: string = '';
searchparams2: string = '';
searchparams3: string = '';
searchparams4: string = '';
searchparams5: string = '';

crtSearch: string = "";
lastAddc: boolean;


includeDefList: boolean = false;
userDefsCT: number;
resCT: number = 0;

searchIdx: number = 0;
searchIdxfinal: number;



itemRows: number;
fhViewRows: number;
lanesView: any = [];
vBtn: number = 0;
  spList1: number = 0;
  spList2: number = 0;
  spList3: number = 0;
  spList4: number = 0;
  spList5: number = 0;
  alertitemTCT: any = 0;
  introPos: number;
  res1: number;
  res2: any;
  res3: any;
  res4: any;
  res5: any;
  ViewCT: number;

  

  sPost: any = [];
  crrReqs: any = [];

 
  showSort: boolean = false;
  sortedL: string = "Age - Newest";
  sortedC: string = "Age - Newest";
  sortedH: string = "Age - Newest";

  searchlistRC: any = [];
  searchlistINV: any = [];

  inCrrReqs: string = "";
  inCrrReqsCT: number = 0;
  tripStops: any = [];
  withStops: boolean = false;


  //POSTING SERVICE
valTemp: string = "";
sView: string = "";
viewSB: boolean = false;
isPosting: boolean = false;
postEdit: boolean = false;

currLane: string = "";
lStopItemed: string;
postType: string;
toDelstops: string = "";
toDelstopsRows: any = [];
doValidate: boolean;

isFrClean: any;
isTsClean: any;
isFrDirty: number = 0;
fHalert: number = 0;
  
 
itemstatus = [
"All Freight",
"Open Freight",
"Unassigned",
"Booked",
"Contracted",
"Transit",
"Delivered",
"Pending Issue",
"Completed",
"Inactive"]



itemFltr: string = "Open Freight";
optFltr: string = "Open Freight";

itemFltrFH: string = "All Freight";
optFltrFH: string = "All Freight";
optReq: boolean = false;
cbalert: number = 0;
statusList: any[];

prevReqCT: number = 0;
prevCrrReqs: string = '';
runCount: number = 0;

notifysound: string = this.apiLink.URL + '/_stream/happy_notification.mp3';
  
//this service frees up app component at load tiem
constructor(public router: Router, public loading: LoadingService,  public navCrtl: NavController,
private alertController: AlertController,private toastCtrl: ToastController, 
public apiLink: ApiLinkService,
public player: MediastreamService
) {

if(isPlatform('ios')) this.isIOS = true;


this.todaySQL = this.sqlReadyDate(new Date().toLocaleDateString());
}




updateUserProfile(u){
return new Promise (resolve =>{
let up = this.apiLink.updateProfile(u).subscribe(async p => {
console.log(p)

  if(p[0].includes("denied or not found")){
  alert('Server did not save changes \nbut this session will execute with changes!\n\nTry again or re-login.');
  resolve(false);
  up.unsubscribe();
  return;
  }
  else{
  resolve(true);
  } 
  up.unsubscribe();
  }, err=>{
    console.log(err);
  });  
 })

}







async openFltr(s){
let ds = [];
let fs = this.itemstatus;
let sl = this.statusList = [];

if(s === 'i'){
//if(this.optReq) return;

ds = this.apiItemsList;
} 
else if(s === 'h') ds = this.inFHistory;


this.statusFltr(fs,ds,sl);
this.goTab('pklist',{src: s});
}





statusFltr(fs: string[], ds: any, sl: any[]) {

fs.filter((s: any)=>{
  let n = 0;   
  let ct = ds.filter((n: any)=>(n.freightitemStatus === s)).length;

    if(s.includes("Freight")){sl.push({status:s,ct: 0}); 
    return;
    }
    ds.filter((i:any) => {            
      if(i.freightitemStatus === s && n===0){
      sl.push({status:s,ct:ct});
      n = 1;
      } 
    })
  });
}




async dolFilter(opt,t){
 // if(this.topSectionScrolls.includes('items_page:')) return
//console.log(t)

if(t.includes('i')){
this.isOpen = "";
this.optFltr = opt; //triggers pipe for no flicker with new data dump
this.itemFltr = opt; //item view label
this.goTab('items_page',null);  
}  
else if(t.includes('h')){
this.isFhOpen = "";
this.optFltrFH = opt; //triggers pipe for no flicker with new data dump
this.itemFltrFH = opt; //item view label
this.goTab('frhistory',null);  
}  



}
  




async addRemoveFav(inv: string,icb){
  


if(icb === "Yes"){
this.removeCbList(inv,false);    
} 
else{


this.addCbList(inv, this.credsInfo.userId, "n");  
} 
}
  
  



async rateCon(record: any){
await new Promise(resolve => {
this.searchlistRC.splice(0,1,record);
//insertsmratecon record into slRC

setTimeout(resolve,1500);
resolve(true);
});
this.goTab('ratecon',null);
}
  




async goInvoice(record: any){
await new Promise(resolve => {
this.searchlistINV.splice(0,1,record);
//insertsmratecon record into slINV

setTimeout(resolve,1500);
resolve(true);
});
this.goTab('item_invoice',null);
}




// GENERATE INVOICE  / POST NUMBER
async genDY(){
  return await new Promise(async resolve => {
    
  
  let date: any = new Date();
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();
  
    const N1 = Math.floor(275 * month / 9);
    const N2 = Math.floor((month + 9) / 12);
    const N3 = (1 + Math.floor((year - 4 * Math.floor(year / 4) + 2) / 3));
    const N = N1 - (N2 * N3) + day - 30;
    // return N;
  
  let y = year.toString(); 
  let m ="" + Math.round(((date - Math.floor(date/1000/60/60/24)*24*60*60*1000)/1000)/60); // minutes in day
  let i: any =  Number(N + "" + y.slice(-3)) + 9000000 + "" + m// Day of Year 80 + Year 023 as string
  //9139 023 123 10001
  //D: 139 Y: 023 + 9000000 + m: 123 + requested seat (10001) allows 10000 per minute
  
  if(!this.loading.isLoading) this.loading.present('initializing..');
    this.connTimer(null,null,false);
    let seat = this.apiLink.apiInvSeat(i,this.credsInfo.userId).subscribe(s => {
    this.dataVal = 'Stop Timer'; // for ConnTimer  
  
    resolve(s[0]);    
    seat.unsubscribe();
    },err => {
      console.log(err);
     });
  });
  
  }
    

//console.log("------ > CLEAR INTERNAL"); 
async clearItemIntv(){

    if(this.syncIntv){
      this.syncProgress = 0;    
      clearInterval(this.syncIntv); 
      this.syncIntv = null; 
    //  console.log("------ > CLEAR INTERNAL"); 
    }           
}



//HERE WORKING
async isCountDown(c: number,n: number){
this.isItemUpd = "Done"; 


//--- neccessary to prevent overSync
await new Promise <boolean>(async (resolve) => {  
  if (this.syncIntv){
  await this.clearItemIntv();
  resolve(true);
  } 
  else resolve(false);
});
//--- neccessary to prevent overSync


if(!this.credsInfo.autoSync) return;


let d = (n/c); // .05
let s: number;
let startedTimer = Date.now();


  this.syncIntv = setInterval(async ()=>{
    s = Math.floor((Date.now() - startedTimer) / n);
    //timer speed in ms
    this.syncProgress = Number((s * d).toFixed(2));

    let res = Number((c/n).toFixed(0));
     console.log(s) //, res, this.syncProgress);

    if(s > res){
    s = 0;
    this.updateItems('i',null,null,null);    
    } 
  }, n); // a little more often in case of drift
}





async toggleINT(){    
this.clearItemIntv(); 
await new Promise(resolve => setTimeout(resolve, 500));  

    if(this.credsInfo.autoSync){
    this.isCountDown(this.itemTimer,this.syncIncr);
    }  
}
  


  // POST TO SERVER - MAIN POST BODY
  async doServerPost(obj: { posted: string; freightitemStatus: string; 
  shipNotes: string; itemInvoice: string; },t: string){
  let res: string;  
  

 this.clearItemIntv();
  return await new Promise(async resolve => {
  
  
  if(obj.posted === ""  ){
      obj.freightitemStatus = "Unassigned";  
      t = "n" //new  e = edit
    }
  

    obj.posted = new Date().toISOString();

  
  if(!this.loading.isLoading) this.loading.present('posting..');
    this.connTimer(null,null,false);
    let data = this.apiLink.apiPosts(obj,t).subscribe(async (ld) => {
    this.dataVal = 'Stop Timer'; // for ConnTimer  
  
    res = ld[0]; 
  
    if(res.includes("error: Invoice already exists")){
    this.fail_post = this.fail_post + obj.itemInvoice;  
    console.log("failed: " + this.fail_post); 
  
    this.lastPostErr = "ERROR...!\n\n" + obj.itemInvoice + " * ALREADY EXISTS\nPLEASE WAIT";
    obj.posted = "EMPTY"; 
   
    await new Promise(resolve => setTimeout(resolve, this.timeDelay )); //needed 
    resolve(false);
    }
    else {
      this.fail_post = "";
      console.log(ld)
      resolve(true);
      } 
    
       
      data.unsubscribe();
      },err => {
      console.log(err);
     }); 
  
  
  });
  
  
  }
  
  
  
  
  //VALIDATION SHIPPER, CARRIER, FREIGHT
  clrCol(col: string) {
  if (this.inputErr.includes(col + ":")) this.inputErr = this.inputErr.replace(new RegExp(col + ":", 'g'),'');
  }
  
  
  
  async valPost(opts: string, obj: any){
  //REPLACE ALL OCCURENCES: PRINCILPLE
  
  this.inputErr = this.inputErr.replace(new RegExp(opts + ":", 'g'),'');
  
  //VALIDATE
  Object.keys(obj).forEach(col => {
  /*
   if(opts === 'all' && this.srchWords(col,["trueRate"])){
   }
   */
        if (this.postType === "n" && this.srchWords(col,
          ["freightOloc", 
          "freightDloc", 
          "freightOGps",
          "freightDGps",
          "freightOZipcode",  
          "freightDZipcode",
          "posted"])) return;

        //ignore empty crr fields when unassigned  
        if (obj.crrId === 0 && col.includes('crr')) return;


          
  
        if(col.includes("ress2")) return;  //Address2
  
        if (opts === 'shipper') if (!col.includes('ship')) return;
        if (opts === 'freight') if (!col.includes('freight')) return;                       
        if (opts === 'carrier') if (!col.includes('crr')) return; 
  
      let value = obj[col];
  
        if (value === null || (typeof (value) === 'string' && value.trim() === '' )) {  
  
          console.log(opts, ": ", col,typeof (value), ": " + value )
          
          if(!this.inputErr.includes(opts + ":")) this.inputErr = this.inputErr + opts  + ":";        
          if(!this.inputErr.includes(col + ":")) this.inputErr = this.inputErr + col + ":";
        return;  
        }
       
        //IF PASSED VALIDATION
        this.clrCol(col);    
      });
      
    if(this.inputErr.length > 0 ){
    console.log("Errors: " + this.inputErr);
    }
  
  }
  
  
  
  
  
async setODloc(){
this.isFrDirty = 1;  
let pi = this.sPost[0];
let ts = this.tripStops

if(!pi){
  pi.freightOloc = "" ; 
  pi.freightDloc = "" ;     
  pi.freightOGps = "" ; 

  pi.freightDGps = "" ;  
  pi.freightOZipcode = "" ; 
  pi.freightDZipcode = "" ; 

  pi.freightStops = 0;
  pi.freightmilesBill = "";  
  console.log("cleared TRIP INFO")
return;    
} 

  pi.freightOloc = ts[0].stopLoc+"";
  pi.freightOGps = ts[0].stopGps+"";
  pi.freightOZipcode = ts[0].stopZipcode+"";
  pi.freightStops = ts.length;      
  console.log("++ORGN INFO")

  
  //DESTINATION LOCATION
  if(ts[1]){
  let n = ts.length -1;
  pi.freightDloc = ts[n].stopLoc+"";
  pi.freightDGps = ts[n].stopGps+"";
  pi.freightDZipcode = ts[n].stopZipcode+"";
  //let oGps = ts[0].stopGps;
  //let dGps = ts[i - 1].stopGps;
  pi.freightmilesBill = "1300";
  console.log("++DEST INFO")
  } 
  else {
    pi.freightDloc = "" ;  
    pi.freightDGps = "" ;  
    pi.freightDZipcode = "" ;       
    pi.freightmilesBill = "";  
    console.log("cleared DEST INFO")
  }   





}


  


//GET STOPS FROM SERVER  
async getStops (x,si){
let cu: string = this.curUrl;

//lsu < lupd

let tx = false;
if(this.last_itemUpdate > this.last_stopsUpdate) tx = true;

if(cu !== "posting" && this.isItemUpd === "Done" 
&& this.tripStops[0] && x === this.tripStops[0].stopInvoice
&& !tx){
console.log("same item");
return;  
} 

return await new Promise(resolve=> {
let lnc = 0;

    this.connTimer(null,null,false);
    let ds = this.apiLink.apiGetStops(x,si).subscribe(async r=>{
    this.dataVal = "Stop Connection Timer";
      

  

      if(r[0] === "not found"){
        if(cu === "posting") this.tripStops = [];  // for carrier switching items
        this.withStops = false;
        } 
       else {


        console.log("GET STOPS: ==================================|| ", 
        this.speCharFix(r).sort(function(a: { stopId: number; }, b: { stopId: number; },){
        if(a.stopId < b.stopId) return -1;
        if(a.stopId > b.stopId) return 1; 
        return 0;
      }));

// RAN BY ALL USERS - DO RENUMBER ONLY FROM SHIPPER EDIT

    if(si === 0){
      this.withStops = true;

      
      this.tripStops = r;


      lnc = r.length;      
    }
    else{
     let ix

      this.tripStops.forEach((a: any, index: number) =>{    

        if(a.stopInvoice === x && a.stopId === si){
        ix = index;  
        this.tripStops[index] = r[0];     
        }               
      })
      
      lnc = r.length;
      console.log(this.tripStops[ix])
    }

  }    

  //let p = this.tripStops;
  //let plot

    this.last_stopsUpdate = new Date().toISOString();
    resolve(lnc);
    ds.unsubscribe();  
    });
  })




}








async stopsMgr(stop: any ,opt: any) {//saveit

  return new Promise(async resolve => {
    
  let p = this.apiLink.optStops(stop, opt).subscribe(async (l) => {
    console.log(l[0]);
     if(l) resolve(true);  
    p.unsubscribe();
    });    
  });


}
  




async laneMgr(lane: any ,opt: any) {//saveit
await this.clearItemIntv();

console.log(lane, opt)

let p= this.apiLink.optLane(lane, opt).subscribe(async l=>{
console.log(l);

  if(opt === 'del'){
    this.userDefs.filter((l: {recid: any}, index: number)=>{

      if(l.recid === lane.recid){
      this.userDefs.splice(index,1);
      }

    })
  }
  else if(opt === 'saveit') await this.getLanes();

 
    if(this.userDefs[0]){
    let n = 0;

    this.userDefs.forEach((d: { recid: number; })=>{
    if(d.recid > 0) n = n + 1;
    })
    this.userDefsCT = n;


    this.userDefs.sort(function(a: { recid: number; }, b: { recid: number; },){
      if(a.recid < b.recid) return -1;
      if(a.recid > b.recid) return 1; 
      return 0;
      });
    } 
       
  p.unsubscribe();
  });    

 
if(this.credsInfo.autoSync && this.apiItemsList[0]) this.updateItems('i',null,'progb',null); 
}




async togSortView(){
if(this.showSort){
  this.showSort = false;
}else this.showSort = true;  
}



async sortOpts(t,i){
if(this.isItemUpd !== "Done") return;
this.showSort = false;

this.dosortOpts(t,i);
}




 
async dosortOpts(t,s){
if(!s){
return;  
} 


let last_sort: string;
let db: any;

if(t.includes("c")){
  last_sort = this.sortedC;
  db = this.inCBoard;
  } 
  else if(t.includes("h")){
  last_sort = this.sortedH;
  db = this.inFHistory;
  } 
  else if(t.includes("l")){
  last_sort = this.sortedL;
  db = this.lanesView;
} 

if(!db) return;





if(!this.isCollapsed && t.includes("btn")){

if(s === "Age - Newest" && last_sort === "Age - Newest") s = "Age - Oldest";

if(s === "Pick - Earliest" && last_sort === "Pick - Earliest") s = "Pick - Latest"; 

if(s === "Rate - Lowest" && last_sort === "Rate - Lowest") s = "Rate - Highest"; 

if(s === "Rate/Mile - Lowest" && last_sort === "Rate/Mile - Lowest") s = "Rate/Mile - Highest"; 

if(s === "DH - Shortest" && last_sort === "DH - Shortest") s = "DH - Longest"; 

if(s === "Trip - Shortest" && last_sort === "Trip - Shortest") s = "Trip - Longest"; 

} 




if(this.isItemUpd === "Done") await new Promise(resolve => setTimeout(resolve, 150));




db.sort(function(a: any , b: any){

let aa: any;
let bb: any;
let sTop: number = -1;  //ASC DEFAULT LESSER  //DESC GREATER
let sBtm: number = 1;


if(s === 'Age - Oldest' || s === 'Age - Newest'){ //AGE
aa = a.posted;
bb = b.posted;

  if(s === 'Age - Newest'){//dsc greater for newest
  sTop = 1;
  sBtm = -1;
  }
}




if(s === 'Trip - Shortest' || s === 'Trip - Longest'){ //DISTANCE
aa = a.freightmilesBill;
bb = b.freightmilesBill;

  if(s === 'Trip - Longest'){//dsc greater
  sTop = 1;
  sBtm = -1;
  }
}




if(s === 'DH - Shortest' || s === 'DH - Longest'){ //DEADHEAD
aa = a.dho;
bb = b.dho;

  if(s === 'DH - Longest'){//dsc greater
  sTop = 1;
  sBtm = -1;
  }
}




if(s === 'Pick - Earliest' || s === 'Pick - Latest'){ //PICKUP DATE
aa = a.freightpickFrom;
bb = b.freightpickFrom;

  if(s === 'Pick - Latest'){//dsc greater
  sTop = 1;
  sBtm = -1;
  }
}





if(s === 'Rate - Lowest' || s === 'Rate - Highest'){ //RATE
aa = a.freightinvRate;
bb = b.freightinvRate;

  if(s === 'Rate - Highest'){//dsc greater
  sTop = 1;
  sBtm = -1;
  }
}





if(s === 'Rate/Mile - Lowest' || s === 'Rate/Mile - Highest'){ //RATE
aa = a.invRpm;
bb = b.invRpm;

  if(s === 'Rate/Mile - Highest'){//dsc greater
  sTop = 1;
  sBtm = -1;
  }
}





if(aa < bb) return sTop;  //ASC ORDER
if(aa > bb) return sBtm; 
});





let g: string;

if(t.includes("c")){
this.sortedC = s;
g = "cbContent"
} 
else if(t.includes("h")){
this.sortedH = s;
g = "fhContent"
} 
else if(t.includes("l")){
this.sortedL = s;
g = "itemsContent"
} 




if(!t.includes("auto")){
await new Promise(resolve => setTimeout(resolve, 150));
this.scrollToElem(g,true,true);  
}




//console.log("VIEW: ", db);
}








async vWlane(x: any,exc: string){
  this.vBtn = x;

let pline: string = "";
if(x === 1) pline = this.searchparams1;
else if(x === 2) pline = this.searchparams2;
else if(x === 3) pline = this.searchparams3;
else if(x === 4) pline = this.searchparams4;
else if(x === 5) pline = this.searchparams5;


this.currLane = pline.slice(0,pline.indexOf('|')).substring(1);

  this.lanesView = await this.apiItemsList
  .filter((p: any) =>  x === p.sCt || x === 0);
  this.itemRows = this.lanesView.length;

  await this.dosortOpts("l-auto",this.sortedL);
  
}





md2Date(d: string){
return d.slice(0, -5).replace(/[-]+/gi, "/");
}




speCharFix(obj: any[]){
Object.keys(obj).forEach(i => Object.keys(obj[i]).forEach(col => {
let value = obj[i][col];

if(!value ) return; // if undefined
if(typeof(value) === "string"){

 obj[i][col] = value
    .replace(/@rn@/gi,"\r\n")
    .replace(/@rcr@/gi,"\r")
    .replace(/@ncr@/gi,"\n")
    .replace(/@bsl@/gi,"\\")
    .replace(/@fsl@/gi,"\/")
    .replace(/@htg@/gi,"#")
    .replace(/@pct@/gi,"%")
    .replace(/@qsm@/gi,"?")
    .replace(/@lbr@/gi,"[")
    .replace(/@rbr@/gi,"]");  
  }

}));

return obj;
}
  



doDate(isoD){
  var d = new Date(isoD);
  d.toLocaleDateString('en-US'); // mm/dd/yyyy
  let res = d.getMonth()+1 +"/" + d.getDate();
  return res;
  }








async hgtResize(id: string, event: any) {
  await new Promise(resolve => setTimeout(resolve, 150)); 

let i = document.getElementById(id);
if(!i) return;

this.detailH  = i.offsetHeight, this.detailW  = i.offsetWidth;

if(i.scrollHeight > i.clientHeight || i.scrollWidth > i.clientWidth){
this.oFlowDets = true;  
} else this.oFlowDets = false;
await new Promise(resolve => setTimeout(resolve, 150)); 
}





async onScroll(event: { 
detail: { deltaY: number; scrollTop: number;},
target: {clientHeight: number; offsetTop: number; lastChild: { offsetTop: number; }} 
},pg: string) {
  
//console.log(event)

 
if(pg.includes(";")){
  pg = pg.slice(0, pg.indexOf(";")) + ":";
}

let d: number = event.detail.deltaY;
let sl: number = 350; //50
//console.log(d)

let st: number = event.detail.scrollTop;
let iSc: boolean = this.topSectionScrolls.includes(pg);

if(st === 0){ // IF EOL
if(iSc){
this.topSectionScrolls = this.topSectionScrolls.replace(pg,'');
} 
return;
}
//
// GETS PRIORITY HERE
    if (d > sl) {
      if(!iSc){
       
       this.topSectionScrolls = this.topSectionScrolls + pg; 
       this.lastScroll = pg;
      } 
    } 
    else if (d < -150)  {  
  //  this.topSectionScrolls = this.topSectionScrolls.replace(pg,'');
    };
};



urNav(){
let pg = this.router.url.split('/').pop();  
  if(pg.includes(";")){
    pg = pg.slice(0, pg.indexOf(";"));
  }
this.curUrl = pg;

this.getprevUrl();
}



async pClear(){ 
await this.clearItemIntv();

this.topSectionScrolls = "";

this.apiItemsList = [];   

if(this.userDefs.length > this.userDefsCT){
  this.userDefs.splice(0, 1);  //CLEAR FIRST POSITON
  console.log("REMOVE CRT")
  }


this.searchparams1 = "";
this.searchparams2 = "";
this.searchparams3 = "";
this.searchparams4 = "";
this.searchparams5 = "";  

this.spList1 = 0;
this.spList2 = 0;
this.spList3 = 0;
this.spList4 = 0;
this.spList5 = 0;

this.res1 = 0;
this.res2 = 0;
this.res3 = 0;
this.res4 = 0;
this.res5 = 0;

this.alertitemTCT = 0;

this.orgSrch = "";
this.dstSrch = "";
this.equSrch = "";
this.frSrch = "";
this.toSrch = "";

this.crtSearch = "";


//this.lastAddc = null;  
this.resCT = 0;
}






public async appFlushUser(){
this.codeId = null; 
this.cpgnId = null;
this.invNbr = null;
this.newpwd = null; 
this.uCpgns = null; 
this.refLink = null;
this.sentPhone = null;
this.optPhone = null;
this.optConf = "";
this.credsInfo = null;  // LOGOUT
}



public sideMenu(){ 
let r = {};
let p = {}; 
let c = {};  
let d = "medium";

if(this.credsInfo.merchant){
r = {title : "Reports Center",
  url   :  "/reports",
  icon  : "stats-chart-outline",
  color : d
  }
} 


if(this.isLoggedIn === 'Shipper' ){
p = {title : "Freight Posting",
  url   :  "/posting",
  icon  : "add-circle",
  color : "success"
  }
} 

if(this.isLoggedIn === 'Carrier' ){
c = {title : "+Lanes (default search)",
  url   :  "/lanes",
  icon  : "swap-horizontal-outline",
  color : d
  }
} 





this.navigate = [ 
 {title : "User\nProfile & Preferences",
  url   :  "/profile",
  icon  : "person-outline",
  color : d
  },
  c,
  r,  
  p,
 
{title : "Logout",
  url   :  "/welcome",
  icon  : "power-outline",
  color : d
  },

{title : "Media",
  url   :  "/queue",
  icon  : "power-outline",
  color : d
  },  
]}






async goTab(aUrl: string, data: any) {
  let pgTi: string;
  
  if(aUrl === 'home') pgTi = "Dashboard";
  if(aUrl === 'items_page') pgTi = "Loads";
  if(aUrl === 'item_search') pgTi = "Load Search";
  if(aUrl === 'item_chalkboard') pgTi = "Chalkboard";
  if(aUrl === 'frhistory') pgTi = "Freight History";
  if(aUrl.includes('lanes')) pgTi = "+Lanes";  // includes for menu link with / prefix from menu
  if(aUrl.includes('posting')) pgTi = "Freight Posting";
  if(aUrl.includes('profile')) pgTi = "Account Profile";
  if(aUrl === 'ratecon') pgTi = "Rate Confirmation";
  if(aUrl.includes('queue')) pgTi = "Media Center";
  if(aUrl.includes('reports')) pgTi = "Reports";
  if(aUrl === 'enroll') pgTi = "Create Profile";
  if(aUrl === 'cart') pgTi = "Shopping Cart";
  if(aUrl === 'item_invoice') pgTi = "Freight Invoice";
  if(aUrl === 'pklist') pgTi = "Freight Status";
  //alert(aUrl + " " + pgTi)
  
  if(this.toolBarOwn.includes(aUrl)){
  this.toolBarClass = aUrl+'BG';   
  } else this.toolBarClass = 'appTbBG'
  
  this.pgTitle = pgTi;
  
  
  if(!data) data = {};
  
  
  //console.log(aUrl, pgTi)
  this.router.navigate(['/app/'+ aUrl, data],{ skipLocationChange: true  });
  }
  
  
  
  
  
  public goToCart(){ 
  if(this.router.url.includes('app/cart')){
  console.log('cart');
  return;   
  }
  this.goTab('cart',null)  
  }
  
  
  public isCharNumber(c: any) {
    return c >= '0' && c <= '9';
    }
  






async connTimer(m: string,t: number,s: any){
this.dataVal = null;
this.msg = m;
this.errMsg = null; 
if(s === 'progb') this.progress = 20; //prevent 0 flicker

if(!t) t = this.timeOutVal;

  if(s){
  this.dblButton = true;
  this.hiddenButton = true; 

  if(m){
  this.loading.present(m); 
  }  
} 


let timer = t;
let ct = 41;
for(
let i=1; i < ct; i++  ){ 
await new Promise(resolve => setTimeout(resolve, timer)); 

if(!this.dataVal){
if(s === 'progb') this.progress = (i * 1.425).toFixed(0);

//if(m) if(i > 15) console.log(i,' Connection timer count',this.progress);   



/*
if(i === 10){
this.msg = 'Slow network connection';
this.showToast('top','warning');   
}   
*/

if(i === 30){
this.msg = 'Very slow network connection';
this.showToast('top','danger');   
}   


if(i === 40 || this.msg === "Connection error!") {
this.msg = 'Connection error!';
this.errMsg = 1; // Allow API message as not leaving page 

this.progress = 0;  
ct = 0;

this.isItemUpd = "Done";
this.dblButton = false;     
this.hiddenButton = false;   
}


}
else{     
      if(s){        

        if(this.msg) if(!this.srchWords(this.msg, ["not found", "denied", "secret pin"]))  this.msg = null; // clear connection faults        
        this.errMsg = null; // clear connection faults
        }  

        if(s === 'progb') this.progress = 100;
      ct = 0;    
  }  
  
}  


await new Promise(resolve => setTimeout(resolve, 600)); 
if(this.loading.isLoading) this.dismissIf();

 
  this.progress = 0;     
  this.dblButton = false;     
  this.hiddenButton = false;  
}







async dismissIf(){
//await new Promise(resolve => setTimeout(resolve, 100)); 
if(this.loading.isLoading) this.loading.dismiss(); //Will dismiss ONLY if running
}



srchWords(text: any, searchWords: any[]){
let multiSearchOr =
    searchWords.some((el) => {
      return text.match(new RegExp(el,"i"))
    });
return multiSearchOr;
}

  
async showToast(p: string, c: string) {

let m = this.msg;  
let d = 3000;
let toast;


if(p === "top"){
toast = this.toastCtrl.create({
message: m,
duration: d,
color: c,
position: 'top',
});

} 
else {
toast = this.toastCtrl.create({
message: m,
duration: d,
color: c,
position: 'bottom',
});

}


(await toast).present();
}







public doChgPwd(){
if(!this.chgPwd){
  this.chgPwd = true;
} else this.chgPwd = false;

}






public async appCheck(entity: string){
return await new Promise<any>((resolve) => { 
let ldata = this.apiLink.getAppData(entity).subscribe(async data => {
let gotVal = data && data[0];

//console.log(gotVal);

if(!gotVal){
resolve(false);
  } 
  else resolve(gotVal.ext);  //if is external or not
  
ldata.unsubscribe();
}, 
err=>{
  this.errMsg = 1;
  console.log(err);
}); 


});
}  





public async getAppStats(entity: string){
return await new Promise((resolve) => {  
//NO TIMER DUE TO SPLASH PAGE CONNECTION MGR
let ldata = this.apiLink.getAppData(entity).subscribe(async data => {
  
let gotVal = data && data[0];
if(!gotVal){
console.log(this.entMsg =  'ENTITY NOT FOUND!');
this.appInfo = null;
resolve(false);
} 
else {
  this.rootId = gotVal.app; //1. first 
  this.appInfo = gotVal; 
  console.log(this.appInfo);

    if(gotVal.ver !== this.appver){

   this.msg = "UPDATES SYNC ERROR"
   await new Promise(resolve => setTimeout(resolve, 2000)); 
   
    window.location.href;  // works for both platforms 
    window.location.replace('/?'+this.rootId);  
    resolve(false); //STOP
    }
    else{
      this.msg = null;
      this.errMsg = null;
      resolve(true); 
    }   
}


ldata.unsubscribe();
}, 
err=>{
  this.errMsg = 1;
  this.msg = "Connection error!";
  console.log(err);
  resolve(false); 
});

});
}



public async userVerifyRefID(){
this.refUser = null;
return await new Promise( (resolve) => {    
let gotVal;  
let findId = this.refCode;

if(findId){
    //NO TIMER DUE TO SPLASH PAGE CONNECTION MGR
    let idSub: any = this.apiLink.getUser(findId,this.rootId,"Match").subscribe(users => {  
    gotVal = users && users[0];

    if(gotVal) { 
      if(gotVal !== "not found"){
          this.refCode = findId;  
          this.refUser = gotVal.firstName + ' '+ gotVal.lastName; 
          this.refProfileGroup = gotVal.profileGroup;
          this.refProfileType = gotVal.profileType;  
          }
          else{
            this.srchCode = findId; // KEEP for welcome page
            this.refCode = "0000"; // INVALID LINK
            this.codeId = null; // INVALID REFERRAL
            this.refLink = null;
          } 
        //  console.log("refCode / CorpID: "+ this.refCode + " refLink: " + this.refLink  + " codeId: " + this.codeId);                      
          resolve(true); 
          idSub.unsubscribe(); 
          return;
          } 
          else {
            resolve(false);
        }
      
      idSub.unsubscribe();           
      }, err=>{
        resolve(false);
      });
  }
});  
} // end method








//6/5/2022
sqlReadyDate(a: any){
  let m = a.substring(0, a.indexOf('/'));  //month  6
  let d = a.slice(m.length + 1, (a.length - 5)); //day   11    slice off 2 out of 4
  let y = a.slice(-4); //year 2022
  if(m.length === 1) m = '0' + m; 
  if(d.length === 1) d = '0' + d;

let res: string = (y + '-' + m + '-' + d)
return res;  // 2022-05-15
}



//2022-05-15
flipSqlDate2Local(a: string){
  let y = a.slice(0, 4); //year 2022
  let m = a.slice(5, -3);  //month  05
  let d = a.slice(-2); //day   15

if(m[0] === "0") m = m.slice(-1); 
if(d[0] === "0") d = d.slice(-1);
let sd = m + '-' + d + '-' + y;
//console.log(sd, " flip SQL dates to Local Date")
return sd; //05-15-2022
}




 
async reqFreight(inv: any, shpr: any, skip: boolean){
let p: any; 
let iN: string = '';

if(!skip){

if(this.inCrrReqs.includes(inv)){
iN = 'Yes';  
p = await this.appConfirm("Withdraw Request","Are you sure?",null,"Yes","No");
}
else{
iN = 'No'; 
p = await this.appConfirm("Request Freight","Are you sure?",null,"Yes","No");  
}

if(p === 3) return; 
}
else{

iN = 'Yes'; //withdraw
}

let c = this.credsInfo;


let d = {invN: inv,
  shipId: shpr,
  userId: c.userId,
  iStatus: ""}



if(iN==='No'){
  
this.addCbList(inv, c.userId, "n"); 

d.iStatus = "Request";  
this.connTimer(null,null,false);
let data = this.apiLink.apiInvRff(d).subscribe(() => {
this.dataVal = "Stop Connection timer";


this.inCrrReqsCT = this.inCrrReqsCT + 1;
this.inCrrReqs = this.inCrrReqs + inv+':';
data.unsubscribe();
},err => { 
  console.log(err)
});   


}
else{
  d.iStatus = "Withdraw";
  this.connTimer(null,null,false);
  let data = this.apiLink.remInvRff(d).subscribe(() => {
  this.dataVal = "Stop Connection timer";

  this.inCrrReqsCT = this.inCrrReqsCT - 1;
  this.inCrrReqs = this.inCrrReqs.replace(inv+':','');
  data.unsubscribe();
  },err => {
     console.log(err)
  });  
}



}






async getRffInfo(x,s,t){
let ui: any = []
 
await new Promise<any>((resolve) => {
  let d = {
    invN: x,  
    userId: s,
    typ: t,
    }
    
  //  console.log(d)
    this.connTimer(null,null,false);
    let data = this.apiLink.getInvRff(d).subscribe(l => {
    this.dataVal = "Stop Connection timer";

    //console.log(d ," INVRFF: ",l);  
    
    if(l[0] === "not found"){
    resolve(false);
    }
    else {
    ui = l;   
    resolve(true);
    }
    data.unsubscribe();
    },
    err => {       
    console.log(err);
    }); 


});

if(!ui) return;
this.crrReqs = ui;
console.log(x,ui);

let inf: string = "";
let nm: number = 0;

this.prevReqCT = this.inCrrReqsCT;
this.prevCrrReqs = this.inCrrReqs;

this.inCrrReqsCT = 0;
this.inCrrReqs = "";



await this.crrReqs.forEach(async (r: any) => {  


this.apiItemsList.filter((i: any) =>{
    if(r.itemInvoice === i.itemInvoice && !inf.includes(i.itemInvoice + ':')){
    inf = inf + r.itemInvoice + ':';
    nm = nm + 1;
    }
});



if(this.isLoggedIn === 'Carrier')
this.inCBoard.filter(c=>{
  if(r.itemInvoice === c.itemInvoice && !inf.includes(r.itemInvoice + ':')){
    inf = inf + r.itemInvoice + ':';
    nm = nm + 1;
    }
});

}); 

// console.log("Requests match in search ", 
this.inCrrReqs = inf;
this.inCrrReqsCT = nm;
//console.log(inf, nm)




}









async lookupUser(i){
return await new Promise(resolve => {
  let data = this.apiLink.getAppUser(i).subscribe(u => {
  //console.log(u[0]);
  resolve(u[0]);

    data.unsubscribe();
    },
    err => {
      console.log(err);
    });  

});
}







async confirmRff(u, sts: string){ //ASSIGN
let ac: any

if(sts === 'Booked')
ac = await this.lookupUser(u);


if(sts === 'Booked' && (!ac || ac === "not found")){
alert("No user profile")
return null;  
}    


let obj = this.sPost[0];
let ud: any;

if(ac && ac !== 'not found')
ud = {
"crrId"  : ac.userId,
"crr"  : ac.companyName,
"crrmcAuth"  : ac.detail1,
"crrdotAuth"  : ac.detail2,
"crrContact"  : ac.displayName,
"crrPhone" : ac.phoneNum,
"crrEmail" : ac.email,
"crrAddress"  : ac.shipAddr,
"crrAddress2" : ac.shipAddr2 + " " + ac.shipAddr3,
"crrCity"  : ac.shipCity,
"crrState"  : ac.shipState,
"crrZipcode"  : ac.shipZip,

"crrData"  : ac.detail3, //admin - factoring etc.. Carrier Detail

"crrNotes"  :  this.qualInput +" - " + new Date().toLocaleDateString(),
}



let d = {
"crrId"  : 0,
"crr"  : "",
"crrmcAuth"  : "",
"crrdotAuth"  : "",
"crrContact"  : "",
"crrPhone"  : "",
"crrEmail"  : "",
"crrAddress"  : "",
"crrAddress2"  : "",
"crrCity"  : "",
"crrState"  : "",
"crrZipcode"  : "",
"crrData"  : "",
"crrNotes"  : "",
};


if(sts === 'Booked' && ac && ac !== 'not found') d = ud;



Object.keys(obj).forEach(col => {

  if(col === "crrId") obj[col] = d.crrId; 
  if(col === "crr") obj[col] = d.crr; 
  if(col === "crrmcAuth") obj[col] = d.crrmcAuth;
  if(col === "crrdotAuth") obj[col] = d.crrdotAuth;
  if(col === "crrContact") obj[col] = d.crrContact;
  if(col === "crrPhone") obj[col] = d.crrPhone;
  if(col === "crrEmail") obj[col] = d.crrEmail;
  if(col === "crrAddress") obj[col] = d.crrAddress;
  if(col === "crrAddress2") obj[col] = d.crrAddress2;
  if(col === "crrCity") obj[col] = d.crrCity;
  if(col === "crrState") obj[col] = d.crrState;
  if(col === "crrZipcode") obj[col] = d.crrZipcode;
  if(col === "crrData") obj[col] = d.crrData;
  if(col === "crrNotes") obj[col] = d.crrNotes;


if(col === "freightitemStatus") obj[col] = sts; 
if(col === "printed") obj[col] = ''; 

})
//console.log("CONFIRM: " ,obj);


return u;  //crrId
}



async wrapUpPost(){
console.log("WRAPPING UP POSTING");

let iEdit = null;

await new Promise(resolve =>{
this.sPost = []; 

if(this.postEdit){
iEdit = 'editpost';
this.goTab('items_page', null);
} 
else this.tripStops = []; //clear from new post


resolve(true);  
});


this.updateItems('i',null,null,iEdit);  
}




async addFhistory(inv: string, crrId: string, typ: string){
  this.apiLink.apiInvUser(inv,"history", crrId, typ).subscribe(l=>{

  });

  
}







async getItems(exc: string, a, lu,sI,sM){


this.ViewCT = 0;  
//console.log("GETTING LOADS");

let te = 0;
if(this.isLoggedIn === "Shipper") te = this.credsInfo.userId
/// UPDATE TO IS THE SHIPPER
  let iSrch: any = {
    "lastupd": lu,
    "ship" : te,      
    "orgn": a.o,
    "dest": a.d,
    "equip": a.e,  
    "froms": a.f,
    "tos": a.t,      
    }
   






let items =[];  
let res: number = 0;

let lc: number = await new Promise<any>(async (ldct) => { 

this.connTimer(null,null,sM);
let data = this.apiLink.apiItems(iSrch).subscribe(async (i) => {
this.dataVal = "Stop Connection timer";
   


    if(i[0] === "not found"){  
    ldct(0);    
    } 
    else if(i[0]){
         
    items = i; //replaces table []
    ldct(i.length); 
    //console.log("GOT: ",i);  
    } 


data.unsubscribe();
},
  async err => {       
console.log(err);

if(this.credsInfo.autoSync){
this.updateItems('i',null,null,null);
} 
});   


});



//console.log("GOT: ",items);  
res = lc;// outside of promise





if((exc === "b" || exc === "x")  && sI === 1){
  //keep last search and set item search
  this.orgSrch = a.o;  
  this.dstSrch = a.d;  
  this.equSrch = a.e;
  this.frSrch = a.f;  //ISO
  this.toSrch = a.t;  //ISO
  
  this.crtSearch = exc;
  this.lastAddc = this.includeDefList;
  this.resCT = res;
     
  }




if(res === 0){  
return false;  
} 
//console.log(res, " IMPORTED")
  this.speCharFix(items);



            items.forEach(async (f: any) => {





              //NEED FILTER FOR SHIPPER 
              //ALERTS ONLY ON UNASSIGNED - REMOVES BOOKED & INACTIVE ON SYNC
              if(this.isLoggedIn === 'Carrier'
              && !this.srchWords(f.freightitemStatus,["Unassigned","Booked","Inactive"])){ 
              return
              } 



              f.sCt = sI; //ASSIGN SEARCH INDEX - MULTILANE SEARCH
              f.inCbList = "No";

              let inApiItems: number = 0; //PUSH               
              let skipAlert: number = 0;

                //IF LOAD EXISTS
                await this.apiItemsList.forEach(async (i: any, index: any) => {

                  if (i.itemInvoice === f.itemInvoice) { 
                      inApiItems = 1; //NO PUSH NEW
                    
                    
                      if(i.modified !== "No"){
                      skipAlert = 1;
                    //  console.log("Skipped ========>: " + i.itemInvoice)  
                      } 

                    //! All 3 allowed for Crr or is Shipper (to view all by shipper)
                    if(f.posted !== i.posted) {
                      
                      //status color Crr unass bookd inact AND chx status to shipper except closed / inactive
                      if(this.isLoggedIn === 'Carrier' 
                      || this.isLoggedIn === 'Shipper' && !this.srchWords(f.freightitemStatus,["Completed","Inactive"])) 
                 
                      f.modified = "Yes";

                      this.apiItemsList[index] = f;

                      if(this.isLoggedIn === 'Carrier'
                      && (f.itemInvoice === this.isOpen  || f.itemInvoice === this.isItem)
                      && f.freightitemStatus !== 'Unassigned'){
                      this.isOpen = '';
                      this.isItem = '';
                      } 

                    }   
                            
                    //REMOVE ALERT IF STATUS STEP DOWN /CLOSURE
                    if(this.isLoggedIn === 'Carrier' && f.freightitemStatus !== "Unassigned"
                        || this.isLoggedIn === 'Shipper' && this.srchWords(f.freightitemStatus,["Completed","Inactive"])){                    

                        if(i.modified !== "No"){ // ALREADY ON ALERT BELL New / Yes

                          if(i.sCt === 1 && this.spList1 > 0){
                            this.spList1 = this.spList1 - 1;
                            this.alertitemTCT = this.alertitemTCT -1;
                          } 
                          if(i.sCt === 2 && this.spList2 > 0){
                            this.spList2 = this.spList2 - 1;
                            this.alertitemTCT = this.alertitemTCT -1;
                          } 
                          if(i.sCt === 3  && this.spList3 > 0){
                            this.spList3 = this.spList3 - 1;
                            this.alertitemTCT = this.alertitemTCT -1;
                          } 
                          if(i.sCt === 4  && this.spList4 > 0){
                            this.spList4 = this.spList4 - 1;
                            this.alertitemTCT = this.alertitemTCT -1;
                          } 
                          if(i.sCt === 5 && this.spList5 > 0){
                            this.spList5 = this.spList5 - 1;
                            this.alertitemTCT = this.alertitemTCT -1;
                          }           
                        }
    
                    
                    } 
                                        
                  }
                });


        
              //CHALKBOARD HEART COLLECT CB DATA BEFORE ITEMS
              await this.inCBoard.filter((c: any) =>{
                if(c.itemInvoice === f.itemInvoice  && this.isLoggedIn === 'Carrier'){
                 f.inCbList = "Yes";                                     
                }    

              });

              
                                                    
              // NEW/ UNASSIGNED SYNC LAST SYNC  
              if(inApiItems === 0 && 
                (f.freightitemStatus === "Unassigned" || this.isLoggedIn === 'Shipper')) {    //push

                if(exc === "i" && f.freightitemStatus === "Unassigned"){

                if( f.inCbList === "Yes") f.modified = "Yes"; 
                else f.modified = "New";
                }             

              this.apiItemsList.push(f); //new items only  
              }
              


              
if (skipAlert === 0 &&
(f.modified === "New" || f.modified === "Yes") &&
(this.isLoggedIn === 'Carrier' && f.freightitemStatus === "Unassigned"
|| this.isLoggedIn === 'Shipper' && !this.srchWords(f.freightitemStatus,["Completed","Inactive"])))
this.ViewCT = this.ViewCT + 1;

//Crr only alerts unass but shipper alerts all except Completed + Inactive
});  //END FOR EACH LOAD IMPORTED

return true;
}
  



async getLanes() {
return new Promise<number>(async resolve => {

if(!this.credsInfo.deflanes){
   resolve(0);
 return 
}



  let p = this.apiLink.apiLanes("pluslane",this.credsInfo.userId).subscribe(async l=>{
   // console.log(l)
    
    if(l[0] === "not found"){
      resolve(0);
      return;
    } 

      if(l[0]){
      this.userDefs = this.speCharFix(l).sort(function(a: { recid: number; }, b: { recid: number; },){
        if(a.recid < b.recid) return -1;
        if(a.recid > b.recid) return 1; 
        return 0;
        });
      this.userDefsCT = l.length;  
      resolve(l.length);       
      } 
    

  p.unsubscribe();
  }); 

});

   


}



//REFRESH
public async getUserLists(t: string){
return await new Promise<boolean> (resolve => {


let ul: any = [];  
let inf: string;
let usr = this.credsInfo.userId;

if(t.includes("c")){
  inf= "chalkboard"
}else inf = "history"


let p = this.apiLink.userLists(inf, usr).subscribe(async l=>{
//console.log(t, " IMPORT ALL HISTORY: ",inf, l)

if(l[0] === "not found"){

  if(t.includes("uc")){ //no match = no longer on fav list per added to Fhistory
    this.cbalert = 0;   
    this.inCBoard.splice(0);    
    console.log("==== > SPLICE ALL CB: "); //REMOVE IF NOT IN IMPORT
    } 

  if(t.includes("uh")){ //no match = no longer on fav list per added to Fhistory
    this.fHalert = 0;   
    this.inFHistory.splice(0);    
    console.log("==== > SPLICE ALL FH: "); //REMOVE IF NOT IN IMPORT
    } 


resolve(true);  
} 

if(l[0]) ul = this.speCharFix(l);


if(inf === "history"){

  await ul.filter((h:any, index) =>{
  if((h.crrId !== usr || h.crrId === 0) && h.freightitemStatus !== "Unassigned"){
    ul[index].freightitemStatus = "Unassigned";
    }
  });
  
//prevent other carrier Status display



//ADDED ONLY BY SYNC - NOT USER OPTIONS  
if(t === "rh"){
this.inFHistory = ul;  

await this.inFHistory.filter((fh, index) =>{
  if(fh.lastAlert < fh.posted){
    this.inFHistory[index].modified = "Yes";
    this.fHalert = this.fHalert + 1;
  }
  });
}
else if(t === "uh"){

let tmp = [];
let n = 0;

await ul.forEach(async (f:any) => { 
n=0;

 await this.inFHistory.filter(async (i: any, index: number)=>{ 
    if(f.itemInvoice === i.itemInvoice){
      n = 1;

      if(i.posted !== f.posted){ 
        //crr === 0 will hide in form
        if(i.modified === "No"){ //not on alert
        this.fHalert = this.fHalert+ 1; 
        }//ALERT

        f.modified = "Yes";        
        this.inFHistory[index] = f; 


        if((f.itemInvoice === this.isFhOpen  || f.itemInvoice === this.isFhItem)
        && this.srchWords(f.freightitemStatus,['Unassigned','Inactive'])){
        this.isFhOpen = '';
        this.isFhItem = ''; 
        } 
      }
    }     
  });


if(n === 0){
console.log("EXTRA")
tmp.push(f); //to prevent adding to FHList yet
} 

}); 

//console.log(tmp)


  tmp.forEach(async (z: any) => {
    z.modified = "Yes";
    await this.inFHistory.push(z);
    this.fHalert = this.fHalert + 1;
  });





//remove after either sync | IF NO LONGER ON SERVER FHISTORY
await this.inFHistory.forEach(async (ih: any, index: number)=>{
let c = 0;

  ul.filter((iu:any)=>{
    if(ih.itemInvoice === iu.itemInvoice){  
    c = 1;
    } 
  })

  if(c === 0) this.inFHistory.splice(index,1);
});

} //END HISTORY OPTIONS
await this.dosortOpts("h-auto",this.sortedH);

}
else 
if(inf === "chalkboard") { //IF CHALKBOARD

if(t === "rc"){

 await ul.forEach(async (c:any) =>{
 let n = 0;
  this.inFHistory.filter(fh=>{
     if(c.itemInvoice === fh.itemInvoice 
      && fh.crrId === usr) n = 1;    
      //in history keep in FAVS in event of unassignment by shipper
  })


  if(n === 0) await this.inCBoard.push(c);
  });
    

   await this.inCBoard.filter((cx, index) =>{
    if(cx.lastAlert < cx.posted){
    this.inCBoard[index].modified = "Yes";  
    this.cbalert = this.cbalert + 1; 
    }
   });

} 
else 
if(t === "uc"){
//CB ONLY EDITS LOAD WITH NO PUSH - AS ITS ADDED BY USER HEART CLICK
await this.inCBoard.forEach(async (i: any, index: number)=>{   

   await ul.filter(async (f:any) => {       
      
      if((f.itemInvoice === this.isCbOpen  || f.itemInvoice === this.isCbItem)
        && this.srchWords(f.freightitemStatus,['Booked','Inactive'])){
        this.isCbOpen = '';
        this.isCbItem = ''; 
        } 

        if(i.itemInvoice === f.itemInvoice){
      
         //in history keep in FAVS in event of unassignment by shipper
          if(f.freightitemStatus === 'Booked' 
          && f.crrId === usr){
            //this.apiLink.remFavItem(f.itemInvoice,"chalkboard", usr);

          if(this.inCBoard.modified !== "No" && this.cbalert > 0) this.clearAlerts(i.itemInvoice);
          
          this.inCBoard.splice(index,1);      
          return;  
          }

          if(i.posted !== f.posted){  
          f.modified = "Yes";   


          if(i.modified === "No"){ //not on alert          
          this.cbalert = this.cbalert + 1;          
          }         
            this.inCBoard[index] = f;  
          }                        
        }
    });
})    
} //END OF CHALKBOARD OPTOINS
await this.dosortOpts("c-auto",this.sortedC);
}


p.unsubscribe();
});


resolve (true);
})

}  









doTimeISO(i,s,pm){
//ISO Date

let myDate = new Date(i);

let result
if(pm === "+"){ //to Millis
result = myDate.getTime() + (s * 1000);
} else result = myDate.getTime() - (s * 1000);


let res = new Date(result).toISOString();
//console.log( res);//this.newLookTime =
return res
}




async updateItems(exc: string, c: any, sm: any, para: string){  

//--- neccessary to prevent overSync
await new Promise <boolean>(async (resolve) => {  
  if (this.syncIntv){
  await this.clearItemIntv();
  resolve(true);
  } 
  else resolve(false);
});
//--- neccessary to prevent overSync



this.isItemUpd = "Searching...";



if(this.isLoggedIn === 'Carrier'){ 


this.apiItemsList.filter(async (i: any, index: any) =>{ // REMOVE ASSIGNED
    if(i.freightitemStatus !== 'Unassigned'){
     
    if(i.modified !== 'No'){

      if(i.sCt === 1 && this.spList1 > 0){
        this.spList1 = this.spList1 - 1;
        this.alertitemTCT = this.alertitemTCT -1;
      } 
      if(i.sCt === 2 && this.spList2 > 0){
        this.spList2 = this.spList2 - 1;
        this.alertitemTCT = this.alertitemTCT -1;
      } 
      if(i.sCt === 3  && this.spList3 > 0){
        this.spList3 = this.spList3 - 1;
        this.alertitemTCT = this.alertitemTCT -1;
      } 
      if(i.sCt === 4  && this.spList4 > 0){
        this.spList4 = this.spList4 - 1;
        this.alertitemTCT = this.alertitemTCT -1;
      } 
      if(i.sCt === 5 && this.spList5 > 0){
        this.spList5 = this.spList5 - 1;
        this.alertitemTCT = this.alertitemTCT -1;
      }                              
    } 
    //KEEP FAVS AS USER MANAGED
    this.apiItemsList.splice(index,1);
    } 
  });
  


  let typ = 'u';
  if(exc === "FIRST RUN"){
  typ = 'r';
  }


//console.log("Checking chalkboard & History")  

await new Promise<any>(async (resolve) => { 
  this.getUserLists(typ+'h').then(async h=>{
  if(h) await new Promise(resolve => setTimeout(resolve, 500 )); 
  if(h === true) resolve(true);
  });
});

await this.getUserLists(typ+'c');
await new Promise(resolve => setTimeout(resolve, 500 )); 
} //END CARRIER TASKS




let newLookTime: any = this.doTimeISO(this.last_itemUpdate, 20, "-");
//console.log(newLookTime)

//Criteria search here
let tct: number = 0;
let incLanes: boolean;
let ud: any = this.userDefs;
let crt: any={};
let lu: string;
this.searchIdx = 0;

incLanes = this.includeDefList;

if(exc === "i"){
  lu =  newLookTime; //INTERVAL or REQUERY

}
else{

lu = ""; 

  if(exc !== "FIRST RUN"){  
    this.topSectionScrolls = "";

    this.apiItemsList = [];  

    if(this.userDefs.length > this.userDefsCT){
    this.userDefs.splice(0, 1);  //CLEAR FIRST POSITON
    console.log("REMOVE CRT")
    }
    
    /* DON'T JUST REMOVE LAST SEARCH  */
    this.searchparams1 = "";
    this.searchparams2 = "";
    this.searchparams3 = "";
    this.searchparams4 = "";
    this.searchparams5 = "";  
    
    this.spList1 = 0;
    this.spList2 = 0;
    this.spList3 = 0;
    this.spList4 = 0;
    this.spList5 = 0;

    this.res1 = 0;
    this.res2 = 0;
    this.res3 = 0;
    this.res4 = 0;
    this.res5 = 0;

    this.alertitemTCT = 0;
  } 
}



///console.log("Initial   exc:" + exc, "+Lanes: " +incLanes, "Equip: "+ this.equSrch);


if(exc === "b" && c){
 crt = c;
} 
else if(!exc.includes("RUN")){
crt = {orgn: this.orgSrch, 
  dest: this.dstSrch, 
  equip: this.equSrch, 
  frs: this.frSrch, 
  tos: this.toSrch, 
  exStates: "",
  minMiles: "",
  minRpm:""
  }

} 
else  {
//PER MANUAL SEARCH RECORD
  this.orgSrch = "";
  this.dstSrch = "";
  this.equSrch = "";
  this.frSrch = "";
  this.toSrch = "";

  this.crtSearch = "";
  this.lastAddc = null;  
  this.resCT = 0;
}


if(exc === "b" && incLanes){
if(ud.length === this.userDefsCT){
ud.splice(0, 0, crt);
//console.log("ADD CRT", ud);
}
exc = "x";  //SWITCH TO X
}


let l: number = 1;    
if(exc !== "b" && incLanes) l = this.userDefs.length;

      for( let i=0; i < l; i++  ){     
      let pass: boolean = true;
      let cx: any;   

          if(!incLanes) cx = crt;       
          else  cx = this.userDefs[i];

          //switch to single letter
          crt =                   
          {o: cx.orgn.trim(), 
          d: cx.dest.trim(), 
          e: cx.equip.trim(), 
          f: cx.frs.trim(), 
          t: cx.tos.trim()
          };

//FIRST STOP @ BTN SEARCH          
if((!crt.o && !crt.d) && this.isLoggedIn === 'Carrier'
  || !crt.e && this.isLoggedIn === 'Carrier'
  || !crt.f 
  || !crt.t 
  ) pass = false;

    let os: string = "Anywhere > ";
    let ds: string = "Anywhere";
    let es: string;
    let fs: string = " Date";
    let ts: string = " - Error";   
    //search parameters

    if(crt.o) os = crt.o +" > ";  
    if(crt.d) ds = crt.d;  
    if(crt.e) es = crt.e;  
    if(crt.f) fs = " * " + this.md2Date(new Date(crt.f).toLocaleDateString()); 
    if(crt.t) ts = " - " + this.md2Date(new Date(crt.t).toLocaleDateString());

    if(pass){    
    let sI = this.searchIdx = this.searchIdx + 1;  
    //COUNT SEARCH INDEXES NOT ITEM INDEX
        
    await new Promise<any>(async (resolve) => { 
    resolve(this.getItems(exc,crt,lu,sI,sm))
    });

    //console.log(this.ViewCT, " RESULT SEARCH");    


     let alc: number = 0;
     if(!this.isPosting)  alc =  this.ViewCT;
        tct = tct + alc;
      
      let sp = sI + os + ds + fs + ts + " " + es + "|";

      if(i === 0){
      this.searchparams1 = sp; 

      this.spList1 = this.spList1 + alc;
      this.res1 = this.apiItemsList.filter((r: {sCt: any})=> r.sCt === sI).length;
      }   
      if(i === 1){
      this.searchparams2 = sp;

      this.spList2 = this.spList2 + alc;
      this.res2 = this.apiItemsList.filter((r: {sCt: any})=> r.sCt === sI).length;
      }  
      if(i === 2){
       this.searchparams3 = sp;

      this.spList3 = this.spList3 + alc;
      this.res3 = this.apiItemsList.filter((r: {sCt: any})=> r.sCt === sI).length;
      }  
      if(i === 3){
      this.searchparams4 = sp;

      this.spList4 = this.spList4 + alc;
      this.res4 = this.apiItemsList.filter((r: {sCt: any})=> r.sCt === sI).length;
      }  
      if(i === 4){
      this.searchparams5 = sp;

      this.spList5 = this.spList5 + alc;
      this.res5 = this.apiItemsList.filter((r: {sCt: any})=> r.sCt === sI).length;
      }  

      
    }


}//END OF LOOP



this.searchIdxfinal = this.searchIdx;
this.alertitemTCT = this.spList1 + this.spList2 + this.spList3 + this.spList4 + this.spList5;
//ONLY POST INTERVAL SERACH 
 

let vl = 0;
if(exc === "i") vl = this.vBtn;

await this.vWlane(vl,exc);

if(exc !== "i" && this.apiItemsList[0]) this.didSrch = true;

//
/*
console.log("AutoSync", this.credsInfo.autoSync,"  exc:" + exc, "+Lanes:" + incLanes, "Equip:" + this.equSrch,
"\n ALT:" + this.alertitemTCT + " pList ct:" + this.apiItemsList.length)
console.log("\n",this.searchparams1 
  +"\n" + this.searchparams2  
  +"\n" + this.searchparams3 
+"\n" + this.searchparams4 
+"\n" + this.searchparams5 );
*/



if(!this.apiItemsList[0] && !this.inCBoard[0] && !this.inFHistory[0]){
  this.goTab('item_search', null);
  this.didSrch = false; //ALLOWS VIEW
} 
else {
  await this.getRffInfo('ALL', this.credsInfo.userId, this.isLoggedIn);
  //get new pull for each global update / posting save

if(this.isLoggedIn === 'Shipper'  
  && !this.isPosting
  && this.inCrrReqsCT > 0 
  && this.inCrrReqsCT > this.prevReqCT){
  this.itemAlertMsg = "CARRIERS REQUESTS\nALERT";
  this.player.stream = new Audio(this.notifysound);
  this.player.stream.play();                  
  this.showItemAlert(exc,crt,-1);   
  }  
  else
  if(this.fHalert > 0){   // PERSIST FOR FREIGHT ACTIVITY

    this.itemAlertMsg = "FREIGHT ACTIVITY\nALERT";                 
    this.showItemAlert(exc,crt,-1);      
  } 
  else
    if(tct > 0){  //IF FOUND ANYTHING NEW 
  
    let s = ""; 
    if(tct > 1) s = "s"; 
    this.itemAlertMsg = tct + " Posting Alert" + s; 
        
    if(exc.includes("RUN") || exc === "x"){
      this.itemAlertMsg = this.itemAlertMsg + "\nbased on default\nsearch settings";
    }                   
    this.showItemAlert(exc,crt,tct);      
  } 





if(exc !== "i" || this.isPosting){

  if(para !== 'editpost'){
    this.optReq = true;
    let s = 'Open Freight';
    this.optFltr = s; //triggers pipe for no flicker with new data dump
    this.itemFltr = s; //item view label
      
    this.topSectionScrolls = this.topSectionScrolls.replace("items_page:",'');
  }


    
      if((this.router.url.includes("item_search") && this.didSrch || this.isPosting)  && this.apiItemsList[0]){

        this.goTab('items_page', null);


        if(para !== 'editpost'){
          await new Promise(resolve => setTimeout(resolve, 100));  

          if(this.isMobiW) this.scrollToElem('topRow',true,false);
          else if(this.isCollapsed) this.scrollToElem('topTab',true,false);

          await new Promise(resolve => setTimeout(resolve, 60)); 
          this.scrollToElem('itemsContent',true,false);            
        }

          
          

      }

     

if(this.isPosting) this.isPosting = false; 
} 


}     






this.dismissIf(); 
this.last_itemUpdate = new Date().toISOString(); 
/*
console.log("SEARCH DONE: ",this.last_itemUpdate = new Date().toISOString(),
"crtSearch: " + this.crtSearch, " exc: " + exc);
*/





if( this.alertitemTCT > 0
  || this.fHalert > 0
  || this.cbalert > 0){
  
  this.player.stream = new Audio(this.notifysound);
  this.player.stream.play(); 
  }




this.runCount = this.runCount + 1;
this.isCountDown(this.itemTimer,this.syncIncr); 
return false; 
}// END UPDATE LOADS






clearAlerts(x){ //101
//return

if(this.isLoggedIn === 'Carrier')
this.inCBoard.filter((c: any)=> {
    if(c.itemInvoice === x && c.modified !== "No"){
      c.modified = "No";

     if(this.cbalert > 0){
    this.cbalert = this.cbalert - 1;

    this.apiLink.apiInvUser(c.itemInvoice,"chalkboard", this.credsInfo.userId, "e").subscribe((l)=>{
    console.log(l);    
    });
    console.log("UPDATE LAST ALERT CHALKBOARD: " + c.itemInvoice 
    + " LA < PO: " , c.lastAlert + "  " + c.posted);  

    } 
  }  
});



  this.apiItemsList.filter((o: any)=> {
  if(o.itemInvoice === x &&  (o.modified !== "No")){
      o.modified = "No"

      if(o.freightitemStatus !== "Unassigned" && this.isLoggedIn === 'Carrier') 
      return;

      if(this.alertitemTCT > 0){
      this.alertitemTCT = this.alertitemTCT -1;

      let nsCt: any = o.sCt;
      if(nsCt === 1 && this.spList1 > 0) this.spList1 = this.spList1 - 1;
      if(nsCt === 2 && this.spList2 > 0) this.spList2 = this.spList2 - 1;
      if(nsCt === 3 && this.spList3 > 0) this.spList3 = this.spList3 - 1;
      if(nsCt === 4 && this.spList4 > 0) this.spList4 = this.spList4 - 1;
      if(nsCt === 5 && this.spList5 > 0) this.spList5 = this.spList5 - 1;
      }

      }       
  });
    
}






clearAlertMsg(){
  if(this.fHalert === 0){
  this.showLA = false;
  this.itemAlertMsg = null;
  }
}





async showItemAlert(exc,crt,altCT){
this.showLA = true;
await new Promise(resolve => setTimeout(resolve, 10000));
this.showLA = false;
this.itemAlertMsg = null;





if(exc !== "i"|| altCT === -1) return;


let itemAl: any  = {
  ent: this.credsInfo.profileGroup,
  userName: this.credsInfo.userName,
  itemDetails: crt.o + " " + crt.d + " " + crt.f + " " + crt.e
  + " Matching item count: " + altCT 
  }  

//console.log(itemAl)
return
this.itemMailer(itemAl);




}







async itemMailer(itemAl: any){

  
  this.connTimer('',null,false);      
  let la: any = this.apiLink.apiItemAlert(itemAl).subscribe(async m => {
  this.dataVal = 'Stop ConnTimer'; 
  
 //console.log(m);
    
  la.unsubscribe();
  }, err=>{
  console.log(err);
  });
  
  
  }







async addCbList(inv: string, crr: string, typ: string){

  let a: boolean = false;
    //skip if already in CB
   await this.inCBoard.filter((c: any) =>{
      if(c.itemInvoice === inv){   
        a = true;
     //   console.log("ALREADY IN CB")   
                               
      }    
    });

if(a)return;     


return await new Promise( async (resolve) => {
await this.apiItemsList.filter((a: any, index: number) => {  


    if( a.itemInvoice === inv){
    this.apiItemsList[index].inCbList = "Yes"
    this.inCBoard.push(a);  
    this.dosortOpts("c-auto",this.sortedC);
    }
  }); 

  this.apiLink.apiInvUser(inv,"chalkboard", crr, typ).subscribe(l=>{
  //console.log(l)    
  resolve(true); 
  });
    
 });
}







async removeAllCbList(){  
await this.apiItemsList.filter((a: any, index: number) => {  
if( a.inCbList === "Yes") this.apiItemsList[index].inCbList = "No"
}); 


this.cbalert = 0;
this.inCBoard.splice(0); //splice All
this.apiLink.remFavItem("0000","chalkboard", this.credsInfo.userId).subscribe(l=>{
//console.log(l)  
});   
  
}
  



async removeCbList(inv,aut){
this.inCBoard.filter(async (b: {itemInvoice: string, modified: string}, index: any) => {

      if(b.itemInvoice  === inv){        

      if(b.modified !== "No" && this.cbalert > 0) this.cbalert = this.cbalert - 1; 

      this.inCBoard.splice(index, 1);  
      
        //REMOVE HEART FROM ITEMS
        await this.apiItemsList.filter((a: any, index: number) => {  
        if(a.itemInvoice === inv) this.apiItemsList[index].inCbList = "No"
        }); 

        if(!this.inCBoard[0] && !aut){
        if(this.apiItemsList[0]) this.goTab('items_page',null);  
        else this.goTab('item_search',null);             
        }
             
        this.apiLink.remFavItem(inv,"chalkboard", this.credsInfo.userId).subscribe(l=>{
       // console.log(l)  
        });        
      }
  });
}




async findRefPhone(){
return await new Promise( (resolve) => {    
  let findLink = this.refLink;
  let gotVal: any;
  if(findLink){
      console.log('finding referral phone' );
      let idSub: any = this.apiLink.getRefPhone(findLink).subscribe(p => { 
      this.dataVal = p[0];   
      console.log(gotVal = p);
        if(gotVal[0] !== "not found link"){
            if(gotVal[0]) this.sentPhone = gotVal[0];
            if(gotVal[1]){
            this.optPhone = gotVal[1];  
            this.optConf = 'Opt-In link already confirmed \n with mobile #: ' + gotVal[1] 
            + '\n to receive updates & announcements.';
            }  
            this.cxPhNum = 0;  
             resolve(true);                                   
            }else{  
              this.refLink = null;
             // console.log("refLINK: " + this.refLink); 
             resolve(false); 
            } 
        idSub.unsubscribe();           
        }, err=>{
          console.log(err);
         resolve(false);
        });
    }else resolve(false); 
  });  
  } // end method






  


  public getprevUrl(){
  let qUrl = this.router.events
  .pipe(filter((e: any) => e instanceof RoutesRecognized),
  pairwise()
  ).subscribe((e: any) => {
  this.prevUrl = e[0].urlAfterRedirects;
  qUrl.unsubscribe();
  }); 
  if(!this.prevUrl)  {
  //console.log('No previous Url');
  }else{
  //console.log('previous URL: ' + this.prevUrl);
  return this.prevUrl
  }
}






public async appConfirm(hdr: string, cmsg: string,txt1: string, txt2: string, txt3: string){
let choice: any;

let alert: any;

if(txt1){
alert = await this.alertController.create({
  header: hdr,
  backdropDismiss: false,
 // message: '<strong>'+cmsg+'</strong>',
  buttons: [
      {
      text: txt1,
      handler: () => {
        alert.dismiss(1);
         return false; // required to return response to function
          }
        },
          {
          text: txt2,
          cssClass: 'secondary',
          handler: () => {
          alert.dismiss(2);
          return false;
          }
        },
        {
        text: txt3,
        cssClass: 'dark',
        handler: () => {
        alert.dismiss(3);
        return false;
        }
      }
    ]});
}
else{
alert = await this.alertController.create({
  header: hdr,
  backdropDismiss: false,
//  message: '<strong>'+cmsg+'</strong>',
  buttons: [
      {
      text: txt2,
      handler: () => {
        alert.dismiss(2);
         return false;
          }
        },
          {
          text: txt3,
          cssClass: 'secondary',
          handler: () => {
          alert.dismiss(3);
         return false;
          }
        }
    ]});  
}





await alert.present();   
choice = await alert.onDidDismiss()

return choice.data;
} //alert end  


      
      











          
                            
// app functions          
//////////////////////////////////////////////////   
public async scrollToElem(ele,pos,s){

  let b = 'smooth'; 
  if(!s) b = 'instant'


  let p
  if(pos) p = { block: 'start', inline: "nearest",  behavior: b };
  else p = { block: 'end', inline: "nearest",  behavior: b };
  

//console.log("scrollELE: ",ele,pos,b);

return await new Promise(resolve => {

setTimeout(()=>{
  let page = document.getElementById(ele);
  
  if (page !== null){
   page.scrollIntoView(p); 
   resolve(true);
  } 
  else{
    resolve(false);
  } 

  }, 10);//RENDER ELE


});


}




async phoneInput(phn: any,bsp: number, enroll: number){
//console.log('backspace ? ', bsp);
  if(bsp === 1){
   //console.log('RETURN');
    return {phn:phn, v:0 };  
  } 




let pl: any = phn.length; 
if(!phn || pl === 0){
//console.log("Nada");
return {phn:phn, v:0 };  
}
let res: any = {phn:phn.slice(0,pl -1), v:0 };
let os: any = phn[pl-1]; // same as slice .charAt(pl-1)
this.keyCt = this.keyCt + 1;
await new Promise(resolve => setTimeout(resolve, 20 )); //20 best results to register input
this.curCt = this.curCt + 1;
if(this.curCt !==  this.keyCt){
this.msg = 'slow down';
return res; 
} 
this.msg = null;
this.keyCt = 0;  
this.curCt = 0;

/* dev keep notes
console.log(pl,phn[pl-2]); // same as .charAt(pl-2) single character 2nd to last
*/
if(!this.isCharNumber(os) && os !== '-'){//console.log("NaN / -");
return res;
} 

let an = phn.replace(/[^0-9]*/g, ''); 
if(an.length > 10){//console.log('++10');
return res;  
}

if(os === '-'){
if(pl > 1 && os === phn[pl-2]){  //console.log("SAME CHAR");
  return res;
  }


  if(pl === 4 || pl === 8 ){ //console.log("3/7");
    }
    else{ //console.log("NOT 3rd 0r 7th character");
  return res; 
  }  
}

//STILL WORKING
if(an.length !== 10){//console.log("still working");  
if(phn.length === 3 || phn.length === 7) phn = phn + '-';
return {phn:phn, v:0 };  
} 
//console.log("COMPLETE"); 
let ac = an.slice(0, 3);
let td = an.slice(3, 6);
let fd = an.slice(-4);
return {phn: ac + '-' + td + '-' + fd, v:1 };
}





   

} 

