import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiLinkService } from '../_service/api-link/api-link.service';
import { AppserviceService } from '../_service/appservice/appservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: 'reports.page.html',
  styleUrls: ['reports.page.scss']
})
export class ReportsPage implements OnInit, OnDestroy{


srchInput: any = "";
match: any = "View All";
selectedSegment = 'network';
  


constructor(public appService: AppserviceService,
public router: Router, public apiLink: ApiLinkService ) { 
//this.viewUsers();  
}

  ngOnDestroy(){
    console.log('DESTROYED')
    return false;
    //throw new Error('Method not implemented.');
  }
        

    // NO ROUTER YET


ngOnInit() {
}   
  


async ionViewDidEnter(){ 
  }




matchChx(){
if(this.match === 'View All'){
this.srchInput = "";  
}

this.appService.usersReportInfo = null;
}




  async viewUsers() {
let m = this.match;  
if(m !== 'View All' && !this.srchInput.replace(/^\s+|\s+$/g, '')){

if(m === 'Match'){
  this.appService.msg = 'Enter a match value';  
}else this.appService.msg = 'Enter a search value';
this.appService.showToast('bottom','primary');
return;  
} 


this.appService.usersReportInfo = null;
this.appService.usersReportInfoCT = null;


let searchValT = (this.srchInput).replace(/^\s+|\s+$/g, '');

let gotVal;

if(!searchValT){
  this.appService.connTimer('Searching....',null,true);  
  let idSub: any = this.apiLink.getUsersList(this.appService.credsInfo.profileGroup).subscribe(async users => {
  this.appService.dataVal = 'Stop ConnTimer';  

  gotVal = users && users[0];

   if(gotVal ==="not found"){
    this.appService.msg = "Users not load";
    this.appService.showToast('bottom','primary');
    }
    else
       {let descUsers = users.sort(function(a: { userId: number; }, b: { userId: number; },){
        if(a.userId < b.userId) return 1;  //DESC ORDER
        if(a.userId > b.userId) return -1; 
        return 0;
        });

        this.appService.usersReportInfo = descUsers; 
        this.appService.usersReportInfoCT = descUsers.length;
     } 
      
      
    idSub.unsubscribe();   
   }, err=>{
    console.log(err);
    });
  }
  else{
    let idSub: any = this.apiLink.getUser(searchValT,this.appService.rootId,this.match).subscribe(async users => {  

    gotVal = users && users[0];

    if(gotVal === "not found"){
      this.appService.msg = this.match + ' not found for: ' + searchValT;
      this.appService.showToast('bottom','primary');
    }
    else
       {let descUsers = users.sort(function(a: { userId: number; }, b: { userId: number; },){
         if(a.userId < b.userId) return 1;  //DESC ORDER
         if(a.userId > b.userId) return -1; 
         return 0;
         });
 
        this.appService.usersReportInfo = descUsers; 
        this.appService.usersReportInfoCT = descUsers.length;
        }   
    
    idSub.unsubscribe();
    }, err=>{
      console.log(err);  
    });
  }
 
}




  
  
}
