import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CanActivateGuard } from './_guard/canAc-app-guard.service';
import { CanDeactivateGuard } from './_guard/canDe-app-guard.service';



const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  { path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  { path: 'queue',
  loadChildren: () => import('./queue/queue.module').then( m => m.QueuePageModule)
},
  { path: 'login',
  loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
},    
  { path: 'app',
  loadChildren: () => import('./_tabs/tabs.module').then(m => m.TabsPageModule)
  },  
  {
    path: 'enroll',
    loadChildren: () => import('./enroll/enroll.module').then( m => m.EnrollPageModule)
  },
  {
    path: 'keepme',
    loadChildren: () => import('./keepme/keepme.module').then( m => m.KeepmePageModule)
  },
  {
    path: 'policyterms',
    loadChildren: () => import('./policyterms/policyterms.module').then( m => m.PolicytermsPageModule)
  },
   {
    path: 'launch-ext',
    loadChildren: () => import('./launch-ext/launch-ext.module').then( m => m.LaunchExtPageModule)
  },
  {path: 'splash', redirectTo: '', pathMatch: 'full'}, // MUST ENTER SUB ACCT url-input-error

  // MUST ENTER SUB ACCT url-input-error
  { 
    path: '**', redirectTo: ''},

];  




@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [
    RouterModule],
    
  providers: [ 
    CanActivateGuard,
    CanDeactivateGuard
  ]
})
export class AppRoutingModule {}
