import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { TabsPage } from './_tabs/tabs.page';
import { ReportsPage } from './reports/reports.page';
import { LoginPage } from './login/login.page';
import { Items_chalkboardPage } from './item_chalkboard/item_chalkboard.page';




@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        NgIdleKeepaliveModule,
        IonicModule.forRoot({ swipeBackEnabled: false, animated: false }),
        //{swipeBackEnabled: false, animated: false }), 
        //NO PERSISTING DISABLE.. apply via tabs navigation. [both os]
        AppRoutingModule
    ],
    providers: [
        TabsPage,
        ReportsPage,
        LoginPage,
        Items_chalkboardPage,
        AppComponent,
        { provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent
    ]
})



export class AppModule {


}
