import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AppserviceService } from "../_service/appservice/appservice.service";


@Injectable({
providedIn: "root"
})
export class CanActivateGuard implements CanActivate {


permit: any = null;  // must be null.... until set also triggered by menu
goToUrl: string;
isLogout: number = 0;



constructor(public router: Router, public appService: AppserviceService) {
    
}


navStats(){
console.log("ROUTE STATS" + this.router.url + ' <> '+ this.goToUrl + ' permit? ' + this.permit);
}




// PROMISE
async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    
console.log(this.goToUrl = '/'+ route.parent.routeConfig.path);





//1.
if(this.router.url === '/' && this.goToUrl === "/"){    
//console.log('allowed root & permit: ' + this.permit);
this.permit = 0;
return true; 
} 





//2. TO GUARDED home
if(this.permit > 0 ){
//console.log('allowed activate bypass');  
this.permit = 0;
return true; 
}


//3. BOUNCE BACK
this.router.navigate([this.router.url],{skipLocationChange: true});  
history.pushState (null, null,this.appService.urlstate); 
history.pushState (null, null,this.appService.urlstate); 
console.log("reject can activate");
return false;


}  // end observable function



} // end of class